import Vue from 'vue'

export const truncate = (str, chars, prepend = null, append = null) => {
  if (!str || typeof chars !== 'number') return str

  let stringified = str.toString()

  // prevent overflown value
  // prevent appending, concat only prepended string
  if (chars > stringified.length) {
    if (prepend) {
      stringified.concat(prepend)
    }
    return stringified
  }

  // for simplicity, using slice() for truncating form end & substring() for truncating from beginning
  let res =
    chars < 0 ? stringified.slice(chars) : stringified.substring(0, chars)

  if (prepend) {
    res = prepend.concat(res)
  }

  if (append) {
    res = res.concat(append.toString())
  }

  return res
}

Vue.prototype.$truncate = truncate
