export default {
  namespaced: true,
  state: () => ({
    filterOption: '',
    appliedFilters: '',
    filterable: '',
    paginationIndex: 1,
    tableData: '',
    tableMeta: '',
    cancelled: 0,
    completed: 0,
    on_trip: 0,
    total: 0,
  }),
  getters: {
    getFilterOption(state) {
      return state.filterOption
    },
    getAppliedFilters(state) {
      return state.appliedFilters
    },
    getFilterable(state) {
      return state.filterable
    },
    getPaginationIndex(state) {
      return state.paginationIndex
    },
    getTableData(state) {
      return state.tableData
    },
    getTableMeta(state) {
      return state.tableMeta
    },
    getCancelled(state) {
      return state.cancelled
    },
    getCompleted(state) {
      return state.completed
    },
    getOnTrip(state) {
      return state.on_trip
    },
    getTotal(state) {
      return state.total
    },
  },
  mutations: {
    SET_FILTER_OPTION(state, payload) {
      state.filterOption = payload
    },
    SET_APPLIED_FILTER(state, payload) {
      state.appliedFilters = payload
    },
    SET_FILTERABLE(state, payload) {
      state.filterable = payload
    },
    SET_PAGINATION_INDEX(state, payload) {
      state.paginationIndex = payload
    },
    SET_TABLE_DATA(state, payload) {
      let { data } = payload
      let { meta } = payload
      let { cancelled, completed, on_trip, total } = payload.meta.summary
      state.cancelled = cancelled
      state.completed = completed
      state.on_trip = on_trip
      state.total = total
      state.tableData = data
      state.tableMeta = meta
    },
  },
  actions: {
    filterOption({ commit }, payload) {
      commit('SET_FILTER_OPTION', payload)
    },
    appliedFilters({ commit }, payload) {
      commit('SET_APPLIED_FILTER', payload)
    },
    filterable({ commit }, payload) {
      commit('SET_FILTERABLE', payload)
    },
    paginationIndex({ commit }, payload) {
      commit('SET_PAGINATION_INDEX', payload)
    },
    tableData({ commit }, payload) {
      commit('SET_TABLE_DATA', payload)
    },
  },
}
