export default {
  /**
   * @param {Object} state
   * @param {String} fstId
   */
  setId(state, fstId) {
    state.fstId = fstId
  },

  /**
   * @param {Object} state
   * @param {Boolean} string
   */
  setEndpoint(state, endpoint) {
    state.endpoint = endpoint
  },

  setQso(state, { prepend = '?', append = '' } = {}) {
    state.qso.prepend = prepend
    state.qso.append = append
  },

  /**
   * Resets qsc, qsa, req & res
   *
   * @param {Object} state
   * @param {String} fstId
   */
  reset(state, fstId) {
    // maybe in future, we might need to reset specific fstId
    // keeping it for reference
    state.resetOn.push([fstId, Date.now()])

    // reset qsc [object]
    for (const key in state.qsc) {
      if (key === 'page') {
        state.qsc.page = 1
        continue
      }
      if (key === 'limit') {
        state.qsc.limit = 10
        continue
      }
      if (key === 'offset') {
        state.qsc.offset = 0
        continue
      }
      // set all other properties as null
      state.qsc[key] = null
    }

    // reset qsa [multidimensional array -> id, key, value]
    state.qsa.forEach((x, i) => {
      if (x[0] === fstId) {
        state.qsa.splice(i, 1)
      }
    })

    // request
    state.req = {
      busy: false,
      failed: false,
      errors: null,
    }

    // response
    state.res = {
      data: null,
      meta: null,
      extra: null,
    }

    // filterables
    state.filterables = {
      __tracker: Date.now(),
    }

    // filterablesApplied
    state.filterablesApplied = {
      __tracker: Date.now(),
    }

    state.organizationCurrency = {
      all: [],
      selected: null,
    }

    state.currency = {
      flag: false,
      selected: 'default',

      attributes: {
        root: '',

        default: {
          key: 'default',
          title: 'Default',
          amount: '',
          currencyName: '',
          currencySymbol: '',
        },

        actual: {
          key: 'actual',
          title: 'Actual',
          amount: '',
          currencyName: '',
          currencySymbol: '',
        },
      },
    }
  },

  /**
   * @param {Object} state
   * @param {Boolean} status
   */
  reqBusy(state, status) {
    state.req.busy = status
    console.log('reqBusy', state.req.busy)
  },

  updateOrganizationCurrency(state, { all, selected }) {
    if (all) {
      state.organizationCurrency.all = all
    }

    if (selected) {
      state.organizationCurrency.selected = selected
      state.qsc.preferred_currency = selected
    }
  },

  /**
   * @param {Object} state
   * @param {Number} page
   */
  updatePage(state, page) {
    const offset = page < 1 ? 0 : (page - 1) * state.qsc.limit

    state.qsc.page = page
    state.qsc.offset = offset
  },

  /**
   * @param {Object} state
   * @param {String} sort (field_query_param)
   * @param {String} order (asc|desc)
   */
  updateSortAndOrder(state, { sort = null, order = null } = {}) {
    state.qsc.sort = sort
    state.qsc.order = order
  },

  /**
   * @param {Object} state
   * @param {Number} limit
   */
  updateLimit(state, limit) {
    state.qsc.limit = limit
  },

  /**
   * @param {Object} state
   * @param {Number} limit
   */
  updateOffset(state, offset) {
    state.qsc.offset = offset
  },

  /**
   * @param {Object} state
   * @param {Number} string
   */
  updateSearch(state, query) {
    state.qsc.search = query
  },

  /**
   *
   * @param {Object} state
   * @param {Object} res is the res.data of axios
   */
  setResponse(state, res) {
    state.res = {
      data: res.data,
      meta: res.meta,
    }
  },

  filterDateRange(
    state,
    { start = null, end = null, startTime = null, endTime = null } = {}
  ) {
    state.qsc.start_date = start
    state.qsc.end_date = end
    state.qsc.start_time = startTime
    state.qsc.end_time = endTime
  },

  /**
   *
   * @param {*} state
   * @param {*} item { id: string, payload: [...{ key:string, value:string }] }
   */
  addQsa(state, { id, payload } = {}) {
    if (typeof id !== 'string' || !Array.isArray(payload)) {
      console.warn('addQsa -> incorrect format', id, payload)
      // todo: also check payload structure?
      return
    }

    state.qsa.set(id, payload)
    state.qsc.__qsa_on = Date.now()

    console.log({ addQsa: state.qsa })
  },

  clearQsa(state, item) {
    if (!item.id) {
      // clear all
      state.qsa.clear()
    } else {
      // clear specified id
      state.qsa.delete(item.id)
    }
    state.qsc.__qsa_on = Date.now()
  },

  // hook for attaching directly qsa string (in qsc)
  appendQsa(state, { payload } = {}) {
    if (typeof payload !== 'string') {
      console.warn('appendQsa -> incorrect format', payload)
      return
    }

    state.qsc.__qsa_append = payload
    state.qsc.__qsa_on = Date.now()

    console.log({ appendQsa: state.qsc.__qsa_append })
  },

  // todo: optimize the code
  addFilterable(state, { key, type, input, store, title } = {}) {
    if (typeof key !== 'string') {
      console.warn('addFilterable -> incorrect format', key, input)
      return
    }
    if (type === 'checkbox-keyed') {
      // it needs the defined keyed store (the component will be TCheckbox)
      let formattedInput = []
      input.forEach((inp) => {
        formattedInput.push({
          key: inp.key,
          type: type,
          input: inp,
          store: inp.uncheckedValue, // default value for TCheckbox
          title: title, // though it won't be printed as it's actually grouped
        })
      })

      formattedInput.length > 0 &&
        (state.filterables[key] = {
          key,
          type,
          input: formattedInput,
          store: 'checkbox-keyed',
          title,
        })

      console.warn(
        'addFilterable -> s s',
        state.filterables[key],
        formattedInput
      )
    } else {
      state.filterables[key] = {
        key,
        type,
        input,
        store,
        title,
      }
    }

    state.filterables.__tracker = Date.now()
  },

  resetFilterables(state) {
    state.filterables = {
      __tracker: Date.now(),
    }
    state.filterablesApplied = {
      __tracker: Date.now(),
    }
  },

  // on update (select/de-select/enable/disable) input, store it's value
  onFilterUpdate(state, { filter, $event, opt } = {}) {
    console.log('onFilterUpdate', { x: filter.key, y: $event })
    if (opt?.checkboxKeyed) {
      const stateFilter = state.filterables[filter.key]
      // const ck = opt.checkboxKeyed
      const cki = opt.checkboxKeyedIndex
      stateFilter.input[cki].store = $event
      console.log('keyed', stateFilter, stateFilter.input[cki])
      return
    }

    state.filterables[filter.key] = { ...filter, ...{ store: $event } }
    // console.log('onFilterUpdated', state.filterables[filter.key])
    state.filterables.__tracker = Date.now()
  },

  onFilterApply(state, keys) {
    keys.forEach((x) => {
      // clone into applied
      state.filterablesApplied[x] = state.filterables[x]

      // clear filter if it doesn't have valid store
      const { key, store, type, input } = state.filterables[x]
      if (
        !store ||
        store === '' ||
        (Array.isArray(store) && store.length < 1)
      ) {
        delete state.filterablesApplied[x]
      }
      if (type === 'checkbox-keyed' && key === 'dispute_refund') {
        const statusArray = input.filter(
          (filterElement) => filterElement.store === 'true'
        )
        if (statusArray.length == 0) {
          delete state.filterablesApplied[x]
        }
      }

      // clear toggle filter if it is unchecked
      // do not need to reset the store to null as it's unchecked
      // and most probably it's already reset from onFilterClear()
      if (type === 'toggle' && store === input.uncheckedValue) {
        delete state.filterablesApplied[x]
      }

      // todo: clear checkbox-keyed filter if it is unchecked
      // if (type === 'checkbox-keyed') {
      //   console.log('checkbox-keyed mutation onApply', input)
      //   input.forEach((inp) => {
      //     console.log(
      //       'checkbox-keyed mutation onApply Input',
      //       state.filterablesApplied[x]
      //     )
      //     if (inp.store === inp.input.uncheckedValue)
      //       delete state.filterablesApplied[x].input[inp.key]
      //   })
      // }

      // clear range filter if it's store is [0, 0]
      if (type === 'range' && store[0] === 0 && store[1] === 0) {
        delete state.filterablesApplied[x]
      }
    })

    // get back to the first page
    state.qsc.page = 1
    state.qsc.offset = 0

    // update the tracker
    state.filterablesApplied.__tracker = Date.now()
  },

  onFilterClear(state, key) {
    delete state.filterablesApplied[key]

    // reset the store
    const f = state.filterables[key]
    if (f.type === 'range' || f.type === 'checkbox') {
      f.store = []
    } else if (f.type === 'checkbox-keyed') {
      f.input.forEach((inp) => {
        inp.store = inp.input.uncheckedValue
      })
    } else {
      f.store = null
    }
    // get back to the first page
    state.qsc.page = 1
    state.qsc.offset = 0

    // update the tracker
    state.filterablesApplied.__tracker = Date.now()
  },

  onFilterClearAll(state) {
    for (const k in state.filterablesApplied) {
      if (k === '__tracker') continue

      delete state.filterablesApplied[k]

      // reset the store
      const f = state.filterables[k]
      if (f.type === 'checkbox') {
        f.store = []
      } else {
        f.store = null
      }
    }
    state.filterablesApplied.__tracker = Date.now()
  },
  setCurrencyEnableFlag(state, { flag }) {
    state.currency.flag = flag
  },

  setCurrency(state, { flag, value } = {}) {
    if (typeof flag === 'boolean') {
      state.currency.flag = flag
    }

    if (typeof value === 'string') {
      const valid = ['default', 'actual']
      if (!valid.includes(value)) {
        console.warn('onCurrencyChange -> invalid currency', value)
        return
      }
      // console.log('onCurrencyChange', value)
      state.currency.selected = value
    }
  },

  setSelectedCurrency(state, { value } = {}) {
    if (typeof value === 'string') {
      state.organizationCurrency.selected = value
    }
  },

  setCurrencyAttributes(state, { root, def, actual }) {
    const attr = state.currency.attributes

    root && (attr.root = root)

    if (def) {
      const { amount, currencyName, currencySymbol } = def
      amount && (attr.default.amount = amount)
      currencyName && (attr.default.currencyName = currencyName)
      currencySymbol && (attr.default.currencySymbol = currencySymbol)
    }

    if (actual) {
      const { amount, currencyName, currencySymbol } = actual
      amount && (attr.actual.amount = amount)
      currencyName && (attr.actual.currencyName = currencyName)
      currencySymbol && (attr.actual.currencySymbol = currencySymbol)
    }
  },
  modifyResponseData(state, { index, key, value }) {
    state.res.data[index][key] = value
  },
}
