export default {
  // xhr request state
  req: {
    // todo: rename to busyInit
    init: true, // is initial request busy
    refetching: false, // refetch all vehicles
    refetchVehicle: false, // refetch a vehicle
    busy: false, // is sub-sequent requests busy
    failed: false,
    errors: [],
  },

  // API response
  resData: {},
  // resMeta: { __tracker: Date.now() },

  // left drawer
  drawerVisibility: true,
  // essentially the bottom drawer is the responsive (mobile) drawer version of left drawer.
  // the template needs to display only one drawer based on screen size
  drawerBottomVisibility: false,

  // drawer view names map
  drawerViews: {
    list: 'LDrawerList',
    details: 'LDrawerDetails',
    summary: 'LDrawerSummary',
  },

  // drawer active view
  drawerView: 'LDrawerSummary',

  // current list view data of filtered / non-filtered resData
  list: {},
  listChunks: [],
  listChunksVisible: [],

  listScrollY: 0,
  listChunkIndex: 0,
  listViewingIndex: 0,

  listSelectAll: false,

  // drawer
  drawer: {
    // view data
    details: {}, // todo: use below object instead (unwrap)
  },

  // current details view data
  details: {},
  // current vehicle geocoder data
  detailsGeocoderData: {},

  // bulk actions panel
  bulkActionExpanded: false,
  bulkActionProcessing: false,

  // hardware action state
  hwA: {
    processing: 0,
    succeeded: 0,
    failed: 0,
    total: 0,
  },
  // {...id: { type: string, logs: object }}
  hwALogs: {},

  // right drawer
  drawerRightVisibility: false,

  /**
   * fetched fleets :: ds
   * {
   *  id: {
   *    id,
   *    name,
   *    geofence: { id, name, speed_limit: { id, speed_choice, speed_value },
   *    coords: {..,..}
   *    city: { id, name }
   *    country: { id, name, currency_symbol, code2, code3 }
   *  },
   * }
   */
  fleetsData: {},

  // Filterable Models
  filters: {
    filterFleet: {
      key: 'filterFleet',
      title: 'Fleet',

      isApplied: false,
      lastAppliedAt: null,

      all: false,
      models: {}, // ds: {'id': boolean}
      labels: {}, // ds: {'id': 'name'}
    },

    filterVehicleType: {
      key: 'filterVehicleType',
      title: 'Type',

      isApplied: false,
      lastAppliedAt: null,

      all: false,
      models: {
        bike: false,
        eBike: false,
        scooter: false,
      },
      labels: {
        bike: 'Bike',
        eBike: 'E-Bike',
        scooter: 'Scooter',
      },
    },

    filterLocationPoint: {
      key: 'filterLocationPoint',
      title: 'Location Point',

      isApplied: false,
      lastAppliedAt: null,

      all: false,
      models: {
        pickup_points: false,
        dropoff_points: false,
      },
      labels: {
        pickup_points: 'Pick up Points',
        dropoff_points: 'Drop off Points',
      },
    },

    filterConnectivity: {
      key: 'filterConnectivity',
      title: 'Connectivity',

      isApplied: false,
      lastAppliedAt: null,

      all: false,
      models: {
        operational: false,
        nonOperational: false,
      },
      labels: {
        operational: 'Active',
        nonOperational: 'Inactive',
      },
    },

    filterLockStatus: {
      key: 'filterLockStatus',
      title: 'Lock',

      isApplied: false,
      lastAppliedAt: null,

      all: false,
      models: {
        locked: false,
        unlocked: false,
      },
      labels: {
        locked: 'Locked',
        unlocked: 'Unlocked',
      },
    },

    filterBatteryStatus: {
      key: 'filterBatteryStatus',
      title: 'Battery',

      isApplied: false,
      lastAppliedAt: null,

      all: false,
      models: {
        low: false,
        medium: false,
        high: false,
      },
      labels: {
        low: 'Low',
        medium: 'Medium',
        high: 'High',
      },
    },

    filterVehicleMode: {
      key: 'filterVehicleMode',
      title: 'Mode',

      isApplied: false,
      lastAppliedAt: null,

      all: false,
      models: {
        available: false,
        unavailable: false,
        rebalance: false,
        maintenance: false,
        swapBattery: false,
      },
      labels: {
        available: 'Available',
        unavailable: 'Unavailable',
        rebalance: 'Rebalance',
        maintenance: 'Maintenance',
        swapBattery: 'Swap Battery',
      },
    },

    filterVehicleStatus: {
      key: 'filterVehicleStatus',
      title: 'Status',

      isApplied: false,
      lastAppliedAt: null,

      all: false,
      models: {
        onTrip: false,
        onReservation: false,
        onParking: false,
        onIllegallyParked: false,
        onCharging: false,
        onGeoFenceAlert: false,
      },
      labels: {
        onTrip: 'On Trip',
        onReservation: 'On Reservation',
        onCharging: 'On Charging',
        onParking: 'On Parking',
        onIllegallyParked: 'Illegally Parked',
        onGeoFenceAlert: 'GeoFence Alert',
      },
    },
  },
  realtimeFilterStatus: false,
  filterLastApplied: null, // filter group name
  filterLastAppliedAt: null, // timestamp

  // only filtered by fleet vehicles
  filteredFleet: {},

  // after filtering, data can be searched & sorted
  // each new filter should reset the followings
  filteredSearch: '',
  filteredSortBy: {
    // empty -> none, 'asc' -> asc, 'desc' -> desc
    qrCode: '',
    battery: '',
    lastMoved: '',
  },
  filteredSortByActive: '',

  // all tags [...{ id: 'uuid', tag: 'Tag'}]
  tagsList: [],
}
