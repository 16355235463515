// import axios from "axios";
// import xMan from "@/utils/xMan";
// import { ApiConfig } from "@/config/ApiConfig";
import { NavigationConfig } from '@/config/NavigationConfig'
import { ApiConfig } from '@/config/ApiConfig'

import createPersistedState from 'vuex-persistedstate'

export default {
  namespaced: true,

  state: {
    // status (idle/busy/succeeded/failed)
    status: 'idle',
    sideCollapsed: true,
    sidebarTheme: 'light-theme',
    // current vehicle data
    sideMenu: NavigationConfig.sideMenu,
    currentListAPIQuery: null,
  },
  getters: {
    getMenu: (state) => {
      return state.sideMenu || []
    },
    getSidebarTheme: (state) => {
      return state.sidebarTheme
    },
    getSidebarCollapsed: (state) => {
      return state.sideCollapsed
    },
    isLoading: (state) => {
      return state.status === 'busy' ?? false
    },
    afterLoginRedirectRoute: (state) => {
      const redirectOptions = state.sideMenu.map((x) => {
        return x.href
      })
      return redirectOptions.length
        ? redirectOptions.includes('/')
          ? '/'
          : redirectOptions[0]
        : '/'
    },
    getCurrentListAPIExportLink: (state) => {
      const current_query = state.currentListAPIQuery
        ? `${state.currentListAPIQuery}&export=true`
        : null
      if (!current_query) return null
      return ApiConfig.base.slice(0, -1) + current_query
    },
  },
  mutations: {
    TOGGLE_SIDEBAR_COLLAPSED(state, payload) {
      state.sideCollapsed = payload
    },
    TOGGLE_SIDEBAR_THEME(state, payload) {
      state.sidebarTheme = payload
    },
    SET_MENU_PAYLOAD(state, payload) {
      // console.log('Filtered menu ->  Nav store :', payload)
      state.sideMenu = payload
    },
    RESET_MENU_PAYLOAD(state) {
      state.sideMenu = NavigationConfig.sideMenu
    },
    STATUS_FAILED(state) {
      state.status = 'failed'
    },
    STATUS_SUCCEEDED(state) {
      state.status = 'succeeded'
    },
    SET_CURRENT_LIST_API_QUERY(state, payload) {
      // console.log('Filtered menu ->  Nav store :', payload)
      state.currentListAPIQuery = payload
    },
  },
  actions: {
    toggleSidebarCollapsed({ commit }, payload) {
      commit('TOGGLE_SIDEBAR_COLLAPSED', payload)
    },
    toggleSidebarTheme({ commit }, payload) {
      commit('TOGGLE_SIDEBAR_THEME', payload)
    },
    setMenuPayloadForPerms({ commit }, payload) {
      let filteredMenu = NavigationConfig.sideMenu.map((item, index) => {
        if (index === 0 && item.header) {
          return item
        } else {
          if (item.permId === undefined && item.child.length > 0) {
            const itemChild = item.child.filter((x) =>
              payload.includes(x.permId)
            )
            if (itemChild.length) {
              return {
                title: item.title,
                icon: item.icon,
                child: [...itemChild],
              }
            } else {
              return 0
            }
          } else {
            if (payload.includes(item.permId)) {
              return item
            } else {
              return 0
            }
          }
        }
      })
      filteredMenu = filteredMenu.filter((x) => x !== 0)
      commit('SET_MENU_PAYLOAD', filteredMenu)
    },
    updateCurrentListAPIQuery({ commit }, payload) {
      commit('SET_CURRENT_LIST_API_QUERY', payload)
    },
  },
  plugins: [createPersistedState()],
}
