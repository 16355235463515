function xMan(data) {
  this.original = data
}

xMan.prototype.toFormData = function() {
  let formData = new FormData()
  // console.log(this.original)
  for (const key in this.original) {
    formData.append(key, this.original[key])
  }
  return formData
}

xMan.prototype.asteriskify = function(keep = 5, max = 0) {
  if (this.original === null || this.original === undefined) {
    return '!*'
  }

  let count, asterisks, kept
  count = this.original.length

  // prevent exposing more than 25% of the total character count
  if (keep >= count || keep > Math.floor((count * 25) / 100)) {
    // display 30% of the total character count
    keep = Math.floor((count * 30) / 100)
  }
  kept = this.original.slice(count - keep)

  asterisks = count - keep
  if (max > 0 && asterisks > max) {
    // prevent exceeding max asterisks number
    asterisks = max
  }

  return '*'.repeat(asterisks) + kept
}

module.exports = xMan
