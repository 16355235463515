export default {
  api: {
    // get
    index: '/dashboard/restricted-areas/',
    // post
    create: '/dashboard/restricted-areas/',
    // get
    details: (id = 'uuid') => {
      return `/dashboard/restricted-areas/${id}/`
    },
    // patch
    update: (id = 'uuid') => {
      return `/dashboard/restricted-areas/${id}/`
    },
    // delete
    delete: (id = 'uuid') => {
      return `/dashboard/restricted-areas/${id}/`
    },
  },
  events: {
    name: 'restricted-area',
    // refresh-index-data
    refresh: `rid-restricted-area`,
    // slideover-right
    sorId: 'restricted-area',
    sorOpen: 'sor-open-restricted-area',
    sorClose: 'sor-close-restricted-area',
    sorToggle: 'sor-toggle-restricted-area',
    // editing-data
    editingData: 'edit-restricted-area-data',
    // viewing-data
    viewingData: 'view-restricted-area-data',
  },
}
