import { httpClient } from '@/services'

export async function useGeocoder({ lat, lng, provider = 'osm' }) {
  let url

  provider === 'osm' &&
    (url = `https://nominatim.openstreetmap.org/reverse?format=jsonv2&lat=${lat}&lon=${lng}`)

  // todo: create a abstract response serializer (to from a common response regardless of providers)
  // use the osm provider response as the default serializer
  return await httpClient.get(url)
}
