/* 
    Rider Store
*/

export default {
  namespaced: true,
  state: () => ({
    filterOption: '',
    appliedFilters: '',
    filterable: '',
    paginationIndex: '',
    tableData: '',
    tableMeta: '',
    totalRider: '',
    activeRider: '',
  }),
  getters: {
    getFilterOption(state) {
      return state.filterOption
    },
    getfiltarable(state) {
      return state.filterable
    },
    getAppliedFilters(state) {
      return state.appliedFilters
    },
    getpaginationIndex(state) {
      return state.paginationIndex
    },
    getTableData(state) {
      return state.tableData
    },
    getTableMeta(state) {
      return state.tableMeta
    },
    getTotalRider(state) {
      return state.totalRider
    },
    getActiveRider(state) {
      return state.activeRider
    },
  },
  mutations: {
    SET_FILTER_OPTIONS(state, payload) {
      state.filterOption = payload
    },
    SET_APPLIED_FILTER(state, payload) {
      state.appliedFilters = payload
    },
    SET_FILTERABLE(state, payload) {
      state.filterable = payload
    },
    SET_PAGINATION_INDEX(state, payload) {
      state.paginationIndex = payload
    },
    SET_TABLE_DATA(state, payload) {
      state.tableData = payload
    },
    SET_TABLE_META(state, payload) {
      state.tableMeta = payload
    },
    SET_TOTAL_RIDER(state, payload) {
      state.totalRider = payload
    },
    SET_ACTIVE_RIDER(state, payload) {
      state.activeRider = payload
    },
  },
  actions: {
    filterOption({ commit }, payload) {
      commit('SET_FILTER_OPTIONS', payload)
    },
    appliedFilters({ commit }, payload) {
      commit('SET_APPLIED_FILTER', payload)
    },
    filterable({ commit }, payload) {
      commit('SET_FILTERABLE', payload)
    },
    paginationIndex({ commit }, payload) {
      commit('SET_PAGINATION_INDEX', payload)
    },
    tableData({ commit }, payload) {
      commit('SET_TABLE_DATA', payload)
    },
    tableMeta({ commit }, payload) {
      commit('SET_TABLE_META', payload)
    },
    totalRider({ commit }, payload) {
      commit('SET_TOTAL_RIDER', payload)
    },
    getActiveRider({ commit }, payload) {
      commit('SET_ACTIVE_RIDER', payload)
    },
  },
}
