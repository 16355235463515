import Vue from 'vue'
import dayjs from 'dayjs'
import { getLocalDateFromUTC } from '@/utils/ConvertDate'

Vue.prototype.$localTime = (
  dateTimeString,
  format = 'DD MMM hh:mm:ss a',
  fallback = '--'
) => {
  if (!dayjs().isValid(dateTimeString)) {
    if (fallback) return fallback
    return
  }

  const localDateTimeString = getLocalDateFromUTC(dateTimeString)
  return dayjs(localDateTimeString).format(format)
}
