/**
 * Automatically imports all the modules and exports as a single module object
 */
const requireModule = require.context('.', true, /\.store\.js$/)
const modules = {}

requireModule.keys().forEach((path) => {
  const fileName = path.replace(/^.*[\\/]/, '')
  // create the module name from file name
  // - remove the store.js
  // - kebab-case to camelCase
  const moduleName = fileName
    .replace(/(\.\/|\.store\.js)/g, '')
    .replace(/-./g, (x) => x.toUpperCase()[1])

  modules[moduleName] = requireModule(path).default || requireModule(path)
})

export default modules
