import Vue from 'vue'
import Toast from 'vue-toastification'
import '@/components/toastification/toastification.css'

const options = {
  transition: 'Vue-Toastification__bounce',
  maxToasts: 20,
  newestOnTop: true,
  pauseOnFocusLoss: false,
  closeOnClick: true,
  showCloseButtonOnHover: false,
  hideProgressBar: false,
  pauseOnHover: true,
  closeButton: 'button',
  draggable: true,
  draggablePercent: 1,
  timeout: 5000,
}

Vue.use(Toast, options)
