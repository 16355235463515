import Vue from 'vue'

// Global Filters
import dayjs from 'dayjs'
import { getLocalDateFromUTC } from '@/utils/ConvertDate'

Vue.filter('capitalize', function(value) {
  if (!value) return ''
  value = value.toString()
  return value.charAt(0).toUpperCase() + value.slice(1)
})

Vue.filter('onlyLastFive', function(value) {
  if (!value) return ''
  value = value.toString()
  return value.slice(-5)
})
// Date Filters

Vue.filter('friendlyDate', function(value) {
  if (!value) return ''
  value = value.toString()
  const localDate = getLocalDateFromUTC(value)
  return dayjs(localDate).format('DD MMM, YYYY')
})

Vue.filter('friendlyDateTime', function(value) {
  if (!value) return ''
  value = value.toString()
  const localDate = getLocalDateFromUTC(value)
  return dayjs(localDate).format('DD MMM, YYYY hh:mm:ss a')
})

Vue.filter('friendlyDateShortYear', function(value) {
  if (!value) return ''
  value = value.toString()
  const localDate = getLocalDateFromUTC(value)
  return dayjs(localDate).format('DD MMM, YY')
})

Vue.filter('friendlyDateWithoutYear', function(value) {
  if (!value) return ''
  value = value.toString()
  const localDate = getLocalDateFromUTC(value)
  return dayjs(localDate).format('DD MMM')
})
