export default {
  getMeta(state) {
    return state.res.meta
  },
  getData(state) {
    return state.res.data
  },
  getPage(state) {
    return state.qsc.page || 1
  },
  getLimit(state) {
    return state.qsc.limit || 10
  },
  getTotal(state) {
    return state.res?.meta?.count?.total || 0
  },
  getOffset: (state) => {
    return (state.qsc.page - 1) * state.qsc.limit
  },
  getPaginationSummary(_state, getters) {
    const limit = getters.getLimit
    const offset = getters.getOffset
    const total = getters.getTotal

    // do not exceed total items
    const fromIndex = offset + 1 < total ? offset + 1 : total
    const toOffset = offset + limit < total ? offset + limit : total

    return `Showing ${fromIndex} - ${toOffset} of ${total} items`
  },
  getSearch: (state) => {
    return state.qsc.search
  },
  getSelectedCurrencyText: (state) => {
    if (state.organizationCurrency.selected) {
      const currency = state.organizationCurrency.all
        .filter((x) => x.id === state.organizationCurrency.selected)
        .pop()

      if (currency) {
        return `${currency.name} (${currency.symbol})`
      }
    }

    return '--'
  },
  getQueryString: (state) => {
    let queryString = state.qso.prepend

    let keys = Object.keys(state.qsc)
    let values = Object.values(state.qsc)

    // qsc
    for (let i = 0; i < keys.length; i++) {
      if (
        typeof values[i] === 'object' ||
        typeof values[i] === 'undefined' ||
        values[i] === ''
      ) {
        continue
      }

      if (Array.isArray(values[i])) {
        // used for arrays
        for (let key in values[i]) {
          queryString += keys[i] + '=' + values[i][key] + '&'
        }
      } else if (keys[i] === '__qsa_append') {
        queryString += `${values[i]}&`
      } else {
        queryString += keys[i] + '=' + values[i] + '&'
      }
    }

    // qsa -> payload: [...{ key:string, value:string }]
    for (const [id, payload] of state.qsa) {
      console.log(id, payload)

      payload.forEach((x) => {
        queryString += `${x.key}=${x.value}&`
      })
    }

    // applied filters
    const af = state.filterablesApplied
    for (const k in af) {
      const store = af[k].store
      if (k === '__tracker' || !store) continue

      if (af[k].type === 'range') {
        const { minQs, maxQs } = af[k].input
        const [minVal, maxVal] = store

        // range -> ?min_qs=x&max_qs=y
        queryString += `${minQs}=${minVal.toString()}&`
        queryString += `${maxQs}=${maxVal.toString()}&`
      } else if (af[k].type === 'checkbox-keyed') {
        const ck = af[k].input
        // console.log('getters for checkbox-keyed', ck)
        ck.forEach((checkboxKeyed, checkboxKeyedIndex) => {
          if (ck[checkboxKeyedIndex].input.checkedValue === checkboxKeyed.store)
            queryString += `${checkboxKeyed.key}=${checkboxKeyed.store}&`
        })
      } else if (Array.isArray(store)) {
        // array type (i.e checkbox) -> ?key=x&key=y&key=z
        store.forEach((x) => (queryString += `${k}=${x}&`))
      } else {
        queryString += `${k}=${store}&`
      }
    }

    const qsr = queryString.slice(0, -1) + state.qso.append
    console.log({ qsr })
    return qsr
  },
  getExportEndpoint: (state, getters) => {
    return state.endpoint + getters.getQueryString + '&export=true'
  },

  /**
   * filterables
   */
  getFilterables(state) {
    let filtered = { ...state.filterables }
    delete filtered.__tracker
    return filtered
  },
  getFilterablesRaw(state) {
    return state.filterables
  },
  getFilterablesSelected(_state, getters) {
    let filtered = { ...getters.getFilterables }
    console.log({ getFilterablesSelected: filtered })

    for (const k in filtered) {
      if (!filtered[k].store) {
        delete filtered[k]
        continue
      }
      if (filtered[k].store.length < 1) {
        delete filtered[k]
        continue
      }
    }
    return filtered
  },
  getFilterablesApplied(state) {
    let filtered = { ...state.filterablesApplied }
    console.log({ app: filtered })
    delete filtered.__tracker

    return filtered
  },
  getFilterablesAppliedCount(_state, getters) {
    return Object.keys(getters.getFilterablesApplied).length || 0
  },
}
