import states from './fs-table.states'
import getters from './fs-table.getters'
import mutations from './fs-table.mutations'
import actions from './fs-table.actions'

export default {
  namespaced: true,
  state: states,
  getters,
  mutations,
  actions,
}
