import Vue from 'vue'
import '@/components/global'
// wip: convert filters into helpers / plugins & never use filters
import '@/utils/Filters'
import '@/plugins'
import '@/assets/css/variable.css'
import App from './App.vue'
import router from './router'
import store from './store'
import { mqInstance } from '@/plugins/media-query'

// wip: use mitt for events
export const eventBus = new Vue()

Vue.config.productionTip = false

new Vue({
  router,
  store,
  mediaQueries: mqInstance,
  render: (h) => h(App),
}).$mount('#app')
