export default {
  namespaced: true,

  state: {
    orderCount: 1,
  },
  getters: {
    getCurrentOrderCount: (state) => {
      return state.orderCount || 1
    },
  },
  mutations: {
    UPDATE_ORDER_COUNT(state, payload) {
      state.orderCount = payload
    },
  },
  actions: {
    saveUpdateOrderCount({ commit }, payload) {
      console.log('UPDATE_ORDER_COUNT', payload)
      commit('UPDATE_ORDER_COUNT', payload)
    },
  },
}
