import Vue from 'vue'

const requireConfig = require.context('@/config/', true, /\.config\.js$/)
const config = {}

requireConfig.keys().forEach((path) => {
  // remove path (keep only the last segment of forward slash)
  const fileName = path.replace(/^.*[\\/]/, '')
  // create the config variable name from file name
  // - remove the config.js
  // - kebab-case to camelCase
  const configName = fileName
    .replace(/(\.\/|\.config\.js)/g, '')
    .replace(/-./g, (x) => x.toUpperCase()[1])

  const context = requireConfig(path).default || requireConfig(path)
  config[configName] = context
  // following is correct for named module exports
  // Object.assign(config, context)
})

Vue.prototype.$config = config
