export default {
  configs: () => {
    return {
      debug: true,
      reconnectInterval: 3000,
      maxReconnectInterval: 30000,
      maxReconnectAttempts: 5,
      timeoutInterval: 5400,
      automaticOpen: true,
    }
  },

  options: () => ({
    debug: true,
    reconnectInterval: 3000,
    maxReconnectInterval: 30000,
    maxReconnectAttempts: 5,
    timeoutInterval: 5400,
    automaticOpen: true,
  }),

  baseUrl: process.env.VUE_APP_WS_API_URL + 'dashboard/',

  channels: {
    notifications: 'notifications/',
    bikeUpdates: 'bike-updates/',
    bikeNotifications: 'bike-notifications/',
  },

  getChannel({ name, token }) {
    const baseUrl = process.env.VUE_APP_WS_API_URL + 'dashboard/'

    const channels = {
      notifications: 'notifications/',
      bikeUpdates: 'bike-updates/',
      bikeNotifications: 'bike-notifications/',
    }

    let channel
    if (channels[name]) {
      channel = baseUrl + channels[name]
    }

    if (token) {
      channel += `?token=${token}&`
    }

    return channel || false
  },
}
