import { useGeocoder } from '@/composables/vendor/useGeocoder'
import { useEndpoints } from '@/composables/api/useEndpoints'
import { httpClient } from '@/services/httpClient'

// current vehicle data
export default {
  namespaced: true,

  state: () => ({
    reqBusy: false,
    reqError: false,
    primaryKey: null,

    details: null,
    lockConfig: null,

    latLng: { lat: null, lng: null },
    geocoderResponse: null,
    lockStatus: null,
    // internals
    _validFetchTypes: ['all', 'details', 'lockConfig'],
  }),

  getters: {
    getFlags(state) {
      return {
        ...state.details?.general_flags,
        ...state.details?.operational_flags,
        ...state.details?.status_flags,
      }
    },
    getLockStatus(state) {
      return state.lockStatus
    },
  },

  mutations: {
    setStatus(state, { busy = false, error = false } = {}) {
      state.reqBusy = busy
      state.reqError = error
    },
    setPk(state, pk) {
      state.primaryKey = pk
    },

    setDetails(state, details) {
      state.details = details
    },
    setFlags(state, { general_flags, operational_flags, status_flags }) {
      state.details.general_flags = general_flags
      state.details.operational_flags = operational_flags
      state.details.status_flags = status_flags
    },
    setLockStatus(state, { status }) {
      state.lockStatus = status
    },
    setLockConfig(state, lockConfig) {
      state.lockConfig = lockConfig
    },

    setLatLng(state, latLng) {
      state.latLng = latLng
    },
    setGeocoderResponse(state, geocoderResponse) {
      state.geocoderResponse = geocoderResponse
    },

    resetState(state) {
      state.reqBusy = false
      state.reqError = false
      state.primaryKey = null

      state.details = null
      state.lockConfig = null
    },

    // payload: { key: speed, value: 'M' }
    updateScooterExtras(state, { key, value }) {
      state.details.scooter_extras[key] = value
    },
  },

  actions: {
    // one fetch method to rule them all
    async fetchData(
      { commit, state },
      { type = 'vehicle', primaryKey = null } = {}
    ) {
      if (!primaryKey) {
        commit('setStatus', { error: true })
        throw new Error('vehicleDetails -> fetchData: No primary key provided')
      }

      if (!state._validFetchTypes.includes(type)) {
        commit('setStatus', { error: true })
        throw new Error('vehicleDetails -> fetchData: No type provided')
      }

      type === 'all ' && commit('resetState')
      commit('setStatus', { busy: true })
      commit('setPk', primaryKey)

      const getUrl = (type) => {
        switch (type) {
          case 'details':
            return httpClient.get(useEndpoints.vehicle.details(primaryKey))
          case 'lockConfig':
            return httpClient.get(
              useEndpoints.vehicle.lockConfigurations(primaryKey)
            )
          default:
            throw new Error(
              `vehicleDetails -> fetchData: Unknown type: ${type}`
            )
        }
      }

      const urls = []
      type === 'all' && urls.push(getUrl('details'), getUrl('lockConfig'))
      ;(type === 'details' || type === 'lockConfig') && urls.push(getUrl(type))

      await httpClient
        .all(urls)
        .then(async (responses) => {
          console.log('vehicleDetails -> fetchData', responses)

          if (type === 'all') {
            const [details, lockConfig] = responses.map((res) => res.data)
            commit('setDetails', details)
            commit('setLockStatus', { status: details.lock.is_locked })
            commit('setLockConfig', lockConfig.data)
          } else if (type === 'details') {
            commit('setDetails', responses[0].data)
            commit('setLockStatus', {
              status: responses[0].data.lock.is_locked,
            })
          } else if (type === 'lockConfig') {
            commit('setLockConfig', responses[0].data.data)
          }

          // geocoder & location
          if (type === 'all' || type === 'details') {
            const [lat, lng] = state.details?.location
              ?.split(',')
              ?.map((n) => parseFloat(n))

            const latLng = { lat, lng }
            commit('setLatLng', latLng)

            await useGeocoder(latLng).then((res) => {
              commit('setGeocoderResponse', res.data)
            })
          }
          return responses
        })
        .catch((err) => {
          console.log('vehicleDetails -> fetchData:', type, err.response)
          commit('setStatus', { error: true })
          throw new Error(err)
        })
        .finally(() => {
          commit('setStatus', { busy: false })
        })
    },
  },
}
