export default {
  api: {
    // get
    index: '/dashboard/trips/',
    // post
    create: '/dashboard/trips/',
    // patch
    update: (id = 'uuid') => {
      return `/dashboard/trips/${id}/`
    },
    single: (id = 'uuid') => {
      return `/dashboard/trips/${id}/`
    },
    vehicleTimelineLog: (id = 'uuid') => {
      return `/dashboard/trips/${id}/vehicle-logs/`
    },
    userTimelineLog: (id = 'uuid') => {
      return `/dashboard/trips/${id}/user-logs/`
    },
    actions: {
      cancel: (tripId = 'uuid') => {
        return `/dashboard/trips/${tripId}/cancel/`
      },
      complete: (tripId = 'uuid') => {
        return `/dashboard/trips/${tripId}/complete/`
      },
    },
  },
  events: {
    name: 'trips',
    // refresh-index-data
    refresh: `rid-trip`,
    // slideover-right
    sorId: 'trip',
    sorOpen: 'sor-open-trip',
    sorClose: 'sor-close-trip',
    sorToggle: 'sor-toggle-trip',
    // editing-data
    editingData: 'edit-trip-data',
    // viewing-data
    viewingData: 'view-trip-data',
  },
}
