export const BRAND_CONFIG = {
  ORG_NAME: 'Sol-ride',
  ORG_LIGHT_LOGO:
    'https://honesto-api-test.s3.ap-southeast-1.amazonaws.com/application_images/sol-ride-white.png',
  ORG_DARK_LOGO:
    'https://honesto-api-test.s3.ap-southeast-1.amazonaws.com/application_images/sol-ride-dark.png',
  LOGO_SIZE: {
    LOGIN_PAGE: {
      WIDTH: 208,
    },
    TOPBAR: {
      WIDTH: 208,
      HEIGHT: 50,
    },
  },
  ORG_EMAIL: 'info@sol-ride.io',
  COLOR: {
    PRIMARY_COLOR: '#000000',
    BUTTON_TEXT_PRIMARY_COLOR: '#ffffff',
    BUTTON_TEXT_SECONDARY_COLOR: '#000000',
    HOMEPAGE_BRAND_TITLE_COLOR: '#fbbf24',
    HOMEPAGE_SVG_LOGO_BG_COLOR: '#fff500',
  },
}
