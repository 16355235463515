export default {
  api: {
    // get
    index: '/dashboard/riders/',
    // get
    indexOfUser: (userId = 'uuid', type = 'franchise_user') => {
      return `/dashboard/riders/?${type}=${userId}/`
    },
    // get
    single: (riderId = 'uuid') => {
      return `/dashboard/riders/${riderId}/`
    },
    // get
    paymentInfo: (riderId = 'uuid') => {
      return `/dashboard/riders/${riderId}/payment-info/`
    },
    // get
    logs: (riderId = 'uuid') => {
      return `/dashboard/riders/${riderId}/logs/`
    },
    // post
    balanceAdjustment: (riderId = 'uuid') => {
      return `/dashboard/riders/${riderId}/`
    },
    // get
    balanceAdjustmentLogs: (riderId = 'uuid') => {
      return `/dashboard/riders/${riderId}/balance-adjustment-records/`
    },
    //get
    referralHistory: (riderId = 'uuid') => {
      return `/dashboard/riders/${riderId}/invitation-records/`
    },
    // // get
    // passSubscriptionRecords: (riderId = 'uuid') => {
    //   return `/dashboard/riders/${riderId}/balance-adjustment-records/`
    // },
  },
  events: {
    refresh: `refresh-rider`,
    adjustBalancePop: `AdjustBalancePopup`,
  },
}
