export const cardBrandIconMap = {
  'VISA': 'fab fa-cc-visa',
  'MASTERCARD': 'fab fa-cc-mastercard',
  'AMEX': 'fab fa-cc-amex',
  'JCB': 'fab fa-cc-jcb',
  'PAYPAL': 'fab fa-cc-paypal',
  'DISCOVER': 'fab fa-cc-discover',
  'DINERS CLUB': 'fab fa-cc-visa',
  'APPLE PAY': 'fab fa-cc-apple-pay',
  'AMAZON PAY': 'fab fa-cc-amazon-pay',
  'OTHERS': 'far fa-credit-card', // far group
}

export function getCreditCardIcon(brand = 'others') {
  const b = brand?.toUpperCase()
  return cardBrandIconMap[b] ?? cardBrandIconMap['OTHERS']
}
