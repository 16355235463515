import Vue from 'vue'

// Vee-validate
import {
  setInteractionMode,
  ValidationObserver,
  ValidationProvider,
} from 'vee-validate/dist/vee-validate.full.esm'

setInteractionMode('custom', (context) => {
  // afterSubmitting -> hasValue -> hasErrors -> listenFor:
  if (context.value === 'yes') {
    return {
      on: ['input', 'change', 'focus'],
    }
  }
  // afterSubmitting -> hasErrors -> listenFor:
  if (context.errors && context.errors.length > 0) {
    return {
      on: ['input', 'change', 'focus'],
    }
  }
  // default
  return {
    on: ['change'],
  }
})

Vue.component('ValidationProvider', ValidationProvider)
Vue.component('ValidationObserver', ValidationObserver)
