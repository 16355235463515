import store from '../store'

// SuperAdmin
export const ifSuperAdmin = (to, from, next) => {
  if (store.getters['auth/status']) {
    const user = store.getters['auth/userInfo']
    if (user.is_superadmin) {
      next()
      return
    }
  } else {
    // Not Authenticated
    next('/login')
  }
  // Not SuperAdmin
  next('/')
}
