// Vehicle Helpers :: todo -> remove vehicle helpers from here
// those should be in otoride.js only
export function getVehicleCategoryByFlag(flag) {
  let aliases = {
    P: 'Bike',
    S: 'Scooter',
    E: 'EBike',
  }
  return flag in aliases ? aliases[flag] : '--'
}

export function getVehicleDomainPortByFlag(flag) {
  let aliases = {
    P: '8192',
    S: '8193',
    E: '8194',
  }
  return flag in aliases ? aliases[flag] : '--'
}

export function genericEquals(x, y) {
  'use strict'

  if (x === null || x === undefined || y === null || y === undefined) {
    return x === y
  }
  // after this just checking type of one would be enough
  if (x.constructor !== y.constructor) {
    return false
  }
  // if they are functions, they should exactly refer to same one (because of closures)
  if (x instanceof Function) {
    return x === y
  }
  // if they are regexps, they should exactly refer to same one (it is hard to better equality check on current ES)
  if (x instanceof RegExp) {
    return x === y
  }
  if (x === y || x.valueOf() === y.valueOf()) {
    return true
  }
  if (Array.isArray(x) && x.length !== y.length) {
    return false
  }

  // if they are dates, they must had equal valueOf
  if (x instanceof Date) {
    return false
  }

  // if they are strictly equal, they both need to be object at least
  if (!(x instanceof Object)) {
    return false
  }
  if (!(y instanceof Object)) {
    return false
  }

  // recursive object equality check
  var p = Object.keys(x)
  return (
    Object.keys(y).every(function(i) {
      return p.indexOf(i) !== -1
    }) &&
    p.every(function(i) {
      return genericEquals(x[i], y[i])
    })
  )
}

/**
 * Compare between two objects if those have same keys & values
 *
 * @param {object} a reference object
 * @param {object} b comparing object
 * @returns {boolean}
 */
export function deepCompareObjects(a, b) {
  if (a === b) return true

  if (typeof a != 'object' || typeof b != 'object' || a == null || b == null)
    return false

  let keysA = Object.keys(a),
    keysB = Object.keys(b)

  if (keysA.length != keysB.length) return false

  for (let key of keysA) {
    if (!keysB.includes(key)) return false

    if (typeof a[key] === 'function' || typeof b[key] === 'function') {
      if (a[key].toString() != b[key].toString()) return false
    } else {
      if (!deepCompareObjects(a[key], b[key])) return false
    }
  }

  return true
}

export function fromSnakeCaseToTitleCase(str) {
  const s = str.replaceAll(/_./g, (m) => {
    return ' ' + m.substr(1).toUpperCase()
  })

  return s[0].toUpperCase() + s.substr(1)
}

/**
 * resolveProp()
 *
 * @param {string} path dot notation path to the value
 * @param {object} obj reference object
 * @returns null|self
 */
export function resolveProp(path, obj) {
  return path.split('.').reduce(function(prev, curr) {
    return prev ? prev[curr] : null
  }, obj || self)
}

/**
 * Slugify a string
 *
 * @param {string} string that needs to converted to slug
 * @returns string
 */
export function slugify(str) {
  str = str.replace(/^\s+|\s+$/g, '') // trim
  str = str.toLowerCase()

  // remove accents, swap ñ for n, etc
  var from = 'åàáãäâèéëêìíïîòóöôùúüûñç·/_,:;'
  var to = 'aaaaaaeeeeiiiioooouuuunc------'

  for (var i = 0, l = from.length; i < l; i++) {
    str = str.replace(new RegExp(from.charAt(i), 'g'), to.charAt(i))
  }

  str = str
    .replace(/[^a-z0-9 -]/g, '') // remove invalid chars
    .replace(/\s+/g, '-') // collapse whitespace and replace by -
    .replace(/-+/g, '-') // collapse dashes
    .replace(/^-+/, '') // trim - from start of text
    .replace(/-+$/, '') // trim - from end of text

  return str
}

export function findIndexById(arr, id) {
  return arr.findIndex((el) => el.id === id)
}

export function nFormatter(num, digits) {
  var si = [
    { value: 1, symbol: '' },
    { value: 1e3, symbol: 'k' },
    { value: 1e6, symbol: 'M' },
    { value: 1e9, symbol: 'G' },
    { value: 1e12, symbol: 'T' },
    { value: 1e15, symbol: 'P' },
    { value: 1e18, symbol: 'E' },
  ]
  var rx = /\.0+$|(\.[0-9]*[1-9])0+$/
  var i
  for (i = si.length - 1; i > 0; i--) {
    if (num >= si[i].value) {
      break
    }
  }
  return (num / si[i].value).toFixed(digits).replace(rx, '$1') + si[i].symbol
}

export function getNetworkStrength(value, suffix = '%') {
  let p = 0
  const v = parseInt(value)

  if (v > 0) {
    p = (v * 100) / 32
  }

  return p.toFixed() + suffix
}
