export default {
  api: {
    // get
    index: '/dashboard/slow-zone-areas/',
    // post
    create: '/dashboard/slow-zone-areas/',
    // get
    details: (id = 'uuid') => {
      return `/dashboard/slow-zone-areas/${id}/`
    },
    // patch
    update: (id = 'uuid') => {
      return `/dashboard/slow-zone-areas/${id}/`
    },
    // delete
    delete: (id = 'uuid') => {
      return `/dashboard/slow-zone-areas/${id}/`
    },
  },
  events: {
    name: 'slow-zone-area',
    // refresh-index-data
    refresh: `rid-slow-zone-area`,
    // slideover-right
    sorId: 'slow-zone-area',
    sorOpen: 'sor-open-slow-zone-area',
    sorClose: 'sor-close-slow-zone-area',
    sorToggle: 'sor-toggle-slow-zone-area',
    // editing-data
    editingData: 'edit-slow-zone-area-data',
    // viewing-data
    viewingData: 'view-slow-zone-area-data',
  },
}
