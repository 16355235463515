export const RiderUserConfig = {
  api: {
    // get
    index: '/dashboard/riders/',
    // get
    single: (id = 'uuid') => {
      return `/dashboard/riders/${id}/`
    },
    // post
    create: '/dashboard/riders/',
    // patch
    update: (id = 'uuid') => {
      return `/dashboard/riders/${id}/`
    },
  },
  events: {
    name: 'rider',
    // refresh-index-data
    refresh: `rid-rider`,
    // slideover-right
    sorId: 'fleet',
    sorOpen: 'sor-open-rider',
    sorClose: 'sor-close-rider',
    sorToggle: 'sor-toggle-rider',
    // editing-data
    editingData: 'edit-rider-data',
    // viewing-data
    viewingData: 'view-rider-data',
  },
}
