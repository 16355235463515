// import dayjs from 'dayjs'

export default {
  fstId: '',
  endpoint: '',

  // query string -> option
  qso: {
    prepend: '?',
    append: '',
  },

  // last reset on (currently not properly implemented)
  // keeping it for future implementation -> reset timestamp for qsc, qsa, req & res
  // it can be a multidimensional array [id, timestamp]
  resetOn: [],

  // query strings -> common
  qsc: {
    page: 1,
    limit: 10,
    offset: 0,
    sort: null,
    order: null,
    search: null,
    start_date: null,
    end_date: null,
    start_time: null,
    end_time: null,
    preferred_currency: null,
    // start_date: dayjs()
    //   .subtract(27, 'day')
    //   .format('YYYY-MM-DD'),
    // end_date: dayjs().format('YYYY-MM-DD'),

    // qsa updated on -> timestamp to trigger getters & watchers
    __qsa_on: null,
    __qsa_append: null,
  },

  // query strings -> additional [filters & others]
  // { id:string, payload:[...{key:string, value:string}] }
  qsa: new Map(),

  // filterables
  filterables: {
    __tracker: Date.now(),
  },

  // filterables applied
  filterablesApplied: {
    __tracker: Date.now(),
  },

  // request
  req: {
    busy: false,
    failed: false,
    errors: null,
  },

  // response
  res: {
    data: null,
    meta: null,
    extra: null,
  },

  organizationCurrency: {
    all: [],
    selected: null,
  },

  currency: {
    flag: false, // has currency filter?
    selected: 'default',

    /**
     * The currency attribute in dot notation to resolve the property from the object
     * Used in the FSTableRowItem component (asCurrency)
     *
     * Original = Default = Org = Bare
     * Settled = Actual = Wrapped
     */
    attributes: {
      root: '', // root prefix in dot notation or empty string

      default: {
        key: 'default',
        title: 'Default',
        amount: '',
        currencyName: '',
        currencySymbol: '',
      },

      actual: {
        key: 'actual',
        title: 'Actual',
        amount: '',
        currencyName: '',
        currencySymbol: '',
      },
    },
  },
}
