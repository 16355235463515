/*
example payload format
{
    step: number [required]
    data: object [optional]
}
*/

export default {
  namespaced: true,

  state: {
    // status (idle/busy/succeeded/failed)
    status: 'idle',
    // current step
    step: undefined,
    // steps data
    step1: null,
    step2: null,
    step3: null,
    // current step that is being edited (undefined/number)
    editingStep: undefined,
    editingFleet: undefined,
    editingFleetId: undefined,
    place_id: undefined,
    latitude: undefined,
    longitude: undefined,
  },
  getters: {
    currentStep: (state) => {
      return state.step || 1
    },
    isEditing: (state) => {
      return !!state.editingStep
    },
    currentEditingStep: (state) => {
      return state.editingStep
    },
    currentEditingFleetId: (state) => {
      return state.editingFleetId || null
    },
  },
  mutations: {
    STATUS_IDLE(state) {
      state.status = 'idle'
    },
    STATUS_BUSY(state) {
      state.status = 'busy'
    },
    STATUS_FAILED(state) {
      state.status = 'failed'
    },
    STATUS_SUCCEEDED(state) {
      state.status = 'succeeded'
    },
    REMEMBER_LATLNG(state, payload) {
      let data = payload.data
      state.latitude = data.latitude
      state.longitude = data.longitude
    },
    REMEMBER_PLACE_ID(state, payload) {
      let data = payload.place_id
      state.place_id = data
    },
    REMEMBER_STEP_DATA(state, payload) {
      let step = payload.step
      let data = payload.data

      if (step === 1) {
        state.step1 = data
      }
      if (step === 2) {
        state.step2 = data
      }
      if (step == 3) {
        state.step3 = data
      }
    },
    FORGET_PLACE_ID_DATA(state) {
      state.place_id = null
    },
    FORGET_STEP_DATA(state, payload) {
      let step = payload.step

      if (step === 1) {
        state.step1 = null
      }
      if (step === 2) {
        state.step2 = null
      }
      if (step === 3) {
        state.step3 = null
      }
    },
    FORGET_ALL_STEPS_DATA(state) {
      state.step1 = null
      state.step2 = null
      state.step3 = null
    },
    REMEMBER_CURRENT_STEP(state, payload) {
      state.step = payload.step
    },
    FORGET_CURRENT_STEP(state) {
      state.step = undefined
    },
    REMEMBER_CURRENT_EDITING_STEP(state, payload) {
      state.editingStep = payload.step
    },
    FORGET_CURRENT_EDITING_STEP(state) {
      state.editingStep = undefined
    },
    REMEMBER_CURRENT_EDITING_FLEET_ID(state, payload) {
      state.editingFleetId = payload.data
    },
    FORGET_CURRENT_EDITING_FLEET_ID(state) {
      state.editingFleetId = undefined
    },
  },
  actions: {
    saveStep({ commit }, payload) {
      commit('STATUS_BUSY')

      commit('REMEMBER_CURRENT_STEP', payload)

      commit('STATUS_SUCCEEDED')
      commit('STATUS_IDLE')
    },
    // syntactic sugar for saveStep
    nextStep({ commit }, payload) {
      commit('STATUS_BUSY')

      commit('REMEMBER_CURRENT_STEP', payload)

      commit('STATUS_SUCCEEDED')
      commit('STATUS_IDLE')
    },
    forgetStep({ commit }) {
      commit('STATUS_BUSY')

      commit('FORGET_CURRENT_STEP')

      commit('STATUS_SUCCEEDED')
      commit('STATUS_IDLE')
    },
    saveEditingStep({ commit }, payload) {
      commit('STATUS_BUSY')

      commit('REMEMBER_CURRENT_EDITING_STEP', payload)

      commit('STATUS_SUCCEEDED')
      commit('STATUS_IDLE')
    },
    forgetEditingStep({ commit }) {
      commit('STATUS_BUSY')

      commit('FORGET_CURRENT_EDITING_STEP')

      commit('STATUS_SUCCEEDED')
      commit('STATUS_IDLE')
    },
    saveEditingFleetId({ commit }, payload) {
      commit('STATUS_BUSY')

      commit('REMEMBER_CURRENT_EDITING_FLEET_ID', payload)

      commit('STATUS_SUCCEEDED')
      commit('STATUS_IDLE')
    },
    forgetEditingFleetId({ commit }) {
      commit('STATUS_BUSY')

      commit('FORGET_CURRENT_EDITING_FLEET_ID')

      commit('STATUS_SUCCEEDED')
      commit('STATUS_IDLE')
    },
    saveStepData({ commit }, payload) {
      commit('STATUS_BUSY')
      commit('REMEMBER_STEP_DATA', payload)
      commit('STATUS_SUCCEEDED')
      commit('STATUS_IDLE')
    },
    forgetStepData({ commit }, payload) {
      commit('STATUS_BUSY')

      commit('FORGET_STEP_DATA', payload)

      commit('STATUS_SUCCEEDED')
      commit('STATUS_IDLE')
    },
    forgetAllStepsData({ commit }) {
      commit('STATUS_BUSY')
      commit('FORGET_ALL_STEPS_DATA')

      commit('STATUS_SUCCEEDED')
      commit('STATUS_IDLE')
    },
    savePlaceId({ commit }, payload) {
      commit('REMEMBER_PLACE_ID', payload)
    },
    removePlaeId({ commit }) {
      commit('FORGET_PLACE_ID_DATA')
    },
    saveLatLng({ commit }, payload) {
      commit('REMEMBER_LATLNG', payload)
    },
    removeLatLng({ commit }) {
      commit('FORGET_LATLNG')
    },
  },
}
