import Vue from 'vue'
// import chunk from 'lodash/chunk'
import {
  serializeVehicleResponse,
  serializeTagResponse,
  validateFilterGroup,
  validFilterGroups,
} from './live-map.helpers'

export default {
  /**
   * Busy state
   */

  reqBusy(state, status) {
    state.req.busy = status
  },

  reqInit(state, status) {
    state.req.init = status
  },

  refetching(state, status) {
    state.req.refetching = status
  },

  reqRefetchVehicle(state, status) {
    state.req.refetchVehicle = status
  },

  /**
   * Response
   */

  syncResponseVehicles(state, apiResponse) {
    const { data } = apiResponse

    data.forEach((el) => {
      Vue.set(state.resData, `${el.id}`, serializeVehicleResponse(el))
      // if (el.id === 'c3f4c0f0-afb3-4626-aba0-914ce5ab918d') {
      //   console.log('el sdsssss', el)
      //   console.log(state.resData[el.id])
      // }
    })
  },

  syncResponseVehicle(state, { id, data }) {
    Vue.set(state.resData, `${id}`, serializeVehicleResponse(data))
  },

  syncResponseFleets(state, apiResponse) {
    // note: meta is absent for this one
    const { data } = apiResponse

    data.forEach((el) => {
      Vue.set(state.fleetsData, `${el.id}`, el)
      Vue.set(state.filters.filterFleet.models, `${el.id}`, false)
      Vue.set(state.filters.filterFleet.labels, `${el.id}`, el.name)
    })

    // console.log({ setF1: state.fleetsData })
    // console.log({ setF2: state.filterFleet.models })
  },

  syncResponseTags(state, { data }) {
    console.log(Array.isArray(data), data)
    data.forEach((el, i) => {
      Vue.set(state.tagsList, i, serializeTagResponse(el))
    })
    // console.log(serializeTag(data[0]))
  },

  /**
   * Drawer Left
   */

  // add data?
  drawerSync(state, { view, visibility }) {
    if (typeof view === 'string') {
      state.drawerView = view
    }

    if (typeof visibility === 'boolean') {
      state.drawerVisibility = visibility
      state.drawerBottomVisibility = visibility
    }
  },

  drawerVisibilityToggle(state) {
    // todo: check if bulk action is processing
    // todo: set toast watcher -> use state -> append msg
    state.drawerVisibility = !state.drawerVisibility
    // mobile responsive version of the left drawer
    state.drawerBottomVisibility = state.drawerVisibility
  },

  onDrawerDetailsView(state, details) {
    state.drawer.details = details
  },

  onDrawerListView(state, data) {
    state.list = {}

    for (const k in data) {
      Vue.set(state.list, `${k}`, data[k])
    }

    // state.listChunks = []
    // const chunked = chunk(Object.values(state.list), 2)
    // chunked.forEach((c, i) => {
    //   Vue.set(state.listChunks, i, c)
    // })

    // // set initial visible data
    // Vue.set(state.listChunksVisible, 0, state.listChunks[0])
  },

  syncListViewingIndex(state, { index = 0 }) {
    state.listViewingIndex = index
  },

  onSelect(state, { key, value }) {
    // const find = state.drawer.list.get(key)
    // find.isSelected = value
    const vehicle = state.list[key]
    if (vehicle) {
      vehicle.isSelected = value
      // state.list.__tracker = Date.now()
    }
  },

  onSelectAll(state, { value }) {
    // console.log({ value })

    for (const k in state.list) {
      // if (k === '__tracker') continue
      // console.log({ x: state.list[k] })
      state.list[k].isSelected = value
    }
    state.listSelectAll = value
    // state.list.__tracker = Date.now()
  },

  /**
   * Update Vehicle
   */

  setVehicleModeProp(state, { id, payload }) {
    const sub = state.resData[id]
    const { propKey, propValue } = payload
    if (sub) {
      sub.vehicleMode = propKey
      sub.vehicleModes[propKey] = propValue

      // also set the respective flags
      if (propKey === 'AV') {
        sub.is_available = true
        // handle AV & UV (opposite of the other)
        sub.vehicleModes.UV = false
        // if it's available rest of the flags are false
        sub.operational_flags.maintainance = false
        sub.operational_flags.rebalance = false
        sub.operational_flags.swap_battery = false
      } else if (propKey === 'MT') {
        sub.operational_flags.maintainance = true
      } else if (propKey === 'RB') {
        sub.operational_flags.rebalance = true
      } else if (propKey === 'SB') {
        sub.operational_flags.swap_battery = true
      } else if (propKey === 'UV') {
        sub.is_available = false
        // handle AV & UV (opposite of the other)
        // if it's not available rest of the flags are kept as it is
        sub.vehicleModes.AV = false
      }
    }
    // update on details screen too? needs identifier from which screen it's committed
  },

  setSubObjProp(state, { id, obj, payload }) {
    const sub = state.resData[id]
    if (sub) {
      sub[obj][payload.propKey] = payload.propValue
    }
  },

  // usually used to update vehicle from details screen
  syncVehicle(state, { id, data }) {
    let sub = state.resData[id]

    if (sub?.id) {
      console.log('syncV', data)
      state.resData[id] = data
    } else {
      console.warn('vehicle not found in state.resData', id)
    }
  },

  // add the notes array to the vehicle (usually should be called
  // upon fetching the items for the first time against a vehicle)
  syncNotes(state, { id, data }) {
    const sub = state.resData[id]

    if (sub) {
      sub.notes = data
      sub.notesLoaded = true
    }
  },

  syncDetailsGeocoderData(state, { data }) {
    state.detailsGeocoderData = data
  },

  // add a single note object to the notes array
  saveNote(state, { id, data }) {
    console.log({ note: data })

    const sub = state.resData[id]

    if (sub) {
      // add the note to the beginning
      sub.notes.unshift(data)
    }
  },

  // { parentId, entityId, entityType }
  deleteNote(state, { parentId, entityId }) {
    const sub = state.resData[parentId]

    const index = sub.notes.findIndex((el) => {
      return el.id === entityId
    })

    console.log({ index, sub, entityId })

    if (index !== -1) {
      // delete one element from the index position
      sub.notes.splice(index, 1)
    }
  },

  onTagCreated(state, { data }) {
    // save the tag to the tagsList
    state.tagsList.push(data)
  },

  // entityId -> vehicleId, data: {id, tag}
  onTagAttached(state, { entityId, data }) {
    // save the tag to the api response
    const vehicle = state.resData[entityId]
    if (Array.isArray(vehicle.tags)) {
      vehicle.tags.push(data)
    }

    // save the tag to the drawer list view

    // const list = state.list[entityId]
    // if (Array.isArray(list.tags)) {
    //   list.tags.push(data)
    // }

    // save the tag to the drawer details view
    // const details = state.drawer.details
    // if (Array.isArray(details.tags)) {
    //   details.tags.push(data)
    // }
  },

  onTagDetached(state, { entityId, data }) {
    // remove the tag from the entity
    const vehicle = state.resData[entityId]
    if (Array.isArray(vehicle.tags)) {
      const i = vehicle.tags.findIndex((el) => el.tag === data.tag)
      if (i !== -1) {
        vehicle.tags.splice(i, 1)
      }
    }
  },

  // bulk actions -> HwA (HardwareAction)
  // expansion
  syncBulkActionExpanded(state, flag) {
    state.bulkActionExpanded = flag
  },

  toggleBulkActionExpanded(state) {
    if (state.bulkActionProcessing) {
      console.log('Bulk action can not be toggled, actions are being executed')
      return
    }

    state.bulkActionExpanded = !state.bulkActionExpanded
  },

  // processing
  syncBulkActionProcessing(state, flag) {
    state.bulkActionProcessing = flag
  },

  toggleBulkActionProcessing(state) {
    // R&D: do i need to toggle bulkActionExpanded as well?
    state.bulkActionProcessing = !state.bulkActionProcessing
  },

  // sync queue status (counters)
  syncHwA(
    state,
    { processing = 0, succeeded = 0, failed = 0, total = 0, resetLogs = false }
  ) {
    state.hwA = { processing, succeeded, failed, total }
    // state.hwA.succeeded = 0

    if (resetLogs) {
      state.hwALogs = {}
    }
  },

  // add entity to logs (type of processing)
  addHwAProcessing(state, { vehicle, logs, count = 1 }) {
    state.hwA.processing += count

    state.hwALogs[vehicle.id] = {
      type: 'processing',
      logs,
      vehicle,
      isExpanded: false,
    }
  },

  // add entity to logs (type of succeeded)
  // todo: update vehicle based on action & vehicle mode
  addHwASucceeded(state, { vehicle, logs, count = 1 }) {
    console.log({ HwASucceeded: vehicle })

    state.hwA.succeeded += count

    if (state.hwA.processing > 0) {
      state.hwA.processing -= count
    }

    state.hwALogs[vehicle.id] = {
      type: 'succeeded',
      logs,
      vehicle,
      isExpanded: false,
    }
  },

  // add entity to logs (type of failed)
  addHwAFailed(state, { vehicle, logs, count = 1 }) {
    state.hwA.failed += count

    if (state.hwA.processing > 0) {
      state.hwA.processing -= count
    }

    state.hwALogs[vehicle.id] = {
      type: 'failed',
      logs,
      vehicle,
      isExpanded: false,
    }
  },

  // set processing type as timeout
  forceTimeout(state) {
    const logs = state.hwALogs

    for (const key in logs) {
      if (logs[key].type === 'processing') {
        logs[key].type = 'timeout'
      }
    }
  },

  // filter
  onFilterChange(state, { group, key, value }) {
    // todo: all select
    const filterGroup = state.filters[group]
    console.log({ filterGroup })

    const currentState =
      key === 'all' ? filterGroup.all : filterGroup.models[key]

    // sync or toggle
    if (typeof value !== 'undefined') {
      if (key === 'all') {
        filterGroup.all = value
      } else {
        filterGroup.models[key] = value
      }
    } else {
      if (key === 'all') {
        filterGroup.all = !currentState
      } else {
        filterGroup.models[key] = !currentState
      }
    }

    if (key === 'all') {
      // toggle all props
      for (const prop in filterGroup.models) {
        filterGroup.models[prop] = !currentState
      }
    } else {
      // auto-update 'all' based on prop states
      let isAllSelected = false
      for (const prop in filterGroup.models) {
        if (filterGroup.models[prop] === false) {
          console.warn(filterGroup.models[prop])
          isAllSelected = false
          break // exit early
        }
        isAllSelected = true
      }
      filterGroup.all = isAllSelected
    }

    // dynamic object - tracker is used to trigger an update for vue-reactivity
    if (group === 'filterFleet') {
      console.log('b4 filterFleet', state.filters.filterFleet.models.__tracker)
      state.filters.filterFleet.models.__tracker = Date.now()
      console.log('a4 filterFleet', state.filters.filterFleet.models.__tracker)
    }
  },

  onFilterGroupApply(state, { group, isApplied = true }) {
    console.log({ onFilterGroupApply: group })

    const filterGroup = state.filters[group]
    console.log({ onFilterGroupApply_Find: filterGroup })

    filterGroup.isApplied = isApplied
    filterGroup.lastAppliedAt = Date.now()
  },

  /**
   * The filter will be cleared but not applied
   * use onFilterApply() ACTION after this one
   * either use single group or an array of groups to clear the selection
   * except is string | null, it'll be skipped
   *
   * @param {*} state
   * @param {*} param1
   * @returns
   */
  onFilterGroupClearSelection(
    state,
    { all = false, except = false, groups, group, isApplied = false }
  ) {
    const groupOrExcept = group || except
    validateFilterGroup(groupOrExcept)
      .then((valid) => console.log({ clearGroup: valid.group }))
      .catch((invalidGroupErr) => console.log(invalidGroupErr))

    // force clear all filters if the group is filterFleet
    if (group === 'filterFleet') {
      all = true
    }

    if (all) {
      for (let i = 0; i < validFilterGroups.length; i++) {
        const filterGroup = state.filters[validFilterGroups[i]]

        filterGroup.all = false
        for (const prop in filterGroup.models) {
          filterGroup.models[prop] = false
        }

        filterGroup.isApplied = isApplied
        filterGroup.lastAppliedAt = null
      }
      console.log({ except })
      return
    }

    // first priority -> except
    if (except && validFilterGroups.includes(except)) {
      for (let i = 0; i < validFilterGroups.length; i++) {
        if (validFilterGroups[i] === except) continue

        const filterGroup = state.filters[validFilterGroups[i]]
        // console.log('cler', valid[i], except, filterGroup, i)

        filterGroup.all = false
        for (const prop in filterGroup.models) {
          filterGroup.models[prop] = false
        }

        filterGroup.isApplied = isApplied
        filterGroup.lastAppliedAt = null
      }
      console.log({ except })
      return
    }

    // second priority -> groups
    if (Array.isArray(groups)) {
      groups.forEach((group) => {
        const filterGroup = state.filters[group]
        // console.log({ onFilterGroupClearSelection_Find: filterGroup })

        filterGroup.isApplied = isApplied
        filterGroup.lastAppliedAt = null

        filterGroup.all = false
        for (const prop in filterGroup.models) {
          filterGroup.models[prop] = false
        }
      })

      return
    }

    // third priority -> group
    const filterGroup = state.filters[group]
    // console.log({ onFilterGroupClearSelection_Find: filterGroup })

    filterGroup.isApplied = isApplied
    filterGroup.lastAppliedAt = null

    filterGroup.all = false
    for (const prop in filterGroup.models) {
      filterGroup.models[prop] = false
    }
  },

  // separate fleet filtered data
  syncFilteredFleet(state, { vehicles, reset = true }) {
    reset && (state.filteredFleet = {})

    for (const k in vehicles) {
      Vue.set(state.filteredFleet, `${vehicles[k].id}`, vehicles[k])
    }
  },

  saveFilterLastAppliedAt(state) {
    // if (typeof time === 'undefined') time = Date.now()

    state.filterLastAppliedAt = Date.now()
  },

  onFilteredSearch(state, v) {
    state.filteredSearch = v
  },
  onChangeRealtimeFilterStatus(state, { status }) {
    state.realtimeFilterStatus = status
    console.log('realtimeFilter', state.realtimeFilter, status)
  },
  onFilteredSort(state, { active }) {
    const sortables = state.filteredSortBy
    for (const k in sortables) {
      if (k === active) {
        // toggle active state
        sortables[k] =
          sortables[k] === ''
            ? 'asc'
            : sortables[k] === 'asc'
            ? 'desc'
            : sortables[k] === 'desc'
            ? 'asc'
            : '' // it won't reach this far :)

        state.filteredSortByActive = active
        continue
      }

      // disable rest of sortable props
      sortables[k] = ''
    }
  },

  /**
   * Drawer Right
   */
  drawerRightVisibilityToggle(state) {
    state.drawerRightVisibility = !state.drawerRightVisibility
  },
}
