export default {
  base: process.env.VUE_APP_BASE_API_URL,
  wsBase: process.env.VUE_APP_WS_API_URL,
  options: {
    headers: {
      'Content-Type': 'multipart/form-data',
      'Accept': 'application/json',
    },
  },
}
