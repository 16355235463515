import Vue from 'vue'

// TailwindCSS & VueTailwindCSS components
import '@/assets/css/tailwind.css'
// todo: use tailwind defaults instead of the custom css for a smaller css file size
import '@/assets/css/custom.css'

import VueTailwind from 'vue-tailwind'

import {
  TInput,
  //   TInputGroup,
  TTextarea,
  TSelect,
  TRichSelect,
  //   TTag,
  TRadio,
  TRadioGroup,
  TCheckbox,
  TCheckboxGroup,
  TToggle,
  TButton,
  //   TCard,
  //   TAlert,
  TTable,
  TPagination,
  TModal,
  TDialog,
  TDropdown,
  //   TDatepicker,
} from 'vue-tailwind/dist/components'

const OTORideTheme = {
  TInput: {
    component: TInput,
    props: {
      fixedClasses:
        'block py-2 px-4 w-full leading-normal rounded outline-none focus:outline-none',
      classes: 'bg-white border focus:border-gray-700 border-gray-300',
      variants: {
        error:
          'text-red-600 bg-red-100 focus:outline-none focus:shadow-outline border border-red-300 rounded py-2 px-4 block w-full leading-normal placeholder-red-300',
        success:
          'text-green-600 bg-green-100 focus:outline-none focus:shadow-outline border border-green-300 rounded py-2 px-4 block w-full leading-normal placeholder-green-300',
        search:
          'z-0 -ml-8 relative py-2 pl-10 pr-2 h-35px w-198px text-sm border rounded-full sm:text-base focus:border-gray-800 focus:outline-none ',
        untraceable:
          'border-0 ring-0 focus:border-0 focus:ring-0 outline-none focus:outline-none bg-transparent font-bold text-22px text-oBlack',
        untraceableAlt: 'outline-none focus:outline-none bg-transparent',
      },
    },
  },

  // TInputGroup

  TTextarea: {
    component: TTextarea,
    props: {
      classes:
        'app-form-reset bg-white outline-none h-20 focus:outline-none border focus:border-gray-700 border-gray-400 rounded py-2 px-4 block w-full leading-normal',
      variants: {
        error:
          'text-red-600 bg-red-100 focus:outline-none focus:shadow-outline border border-red-300 rounded py-2 px-4 block w-full leading-normal placeholder-red-300',
        success:
          'text-green-600 bg-green-100 focus:outline-none focus:shadow-outline border border-green-300 rounded py-2 px-4 block w-full leading-normal placeholder-green-300',
        fun:
          'bg-orange-100 block border-2 border-orange-300 focus:border-orange-500 focus:outline-none leading-normal px-4 py-3 rounded-lg shadow-inner w-full placeholder-orange-300',
        readonly:
          'bg-oLightestGray text-oLightGray placeholder-ODark outline-none h-20 focus:outline-none border border-gray-400 rounded py-2 px-4 block w-full leading-normal',
      },
    },
  },

  TSelect: {
    component: TSelect,
    props: {
      fixedClasses:
        'block pl-3 pr-10  transition duration-100 ease-in-out border rounded shadow-sm focus:ring-0 focus:outline-none disabled:opacity-50 disabled:cursor-not-allowed',
      classes:
        'py-1.5 text-oDark placeholder-gray-400 bg-white border-gray-300 focus:border-gray-600 ',
      variants: {
        danger:
          'py-1.5 border-red-300 bg-red-50 placeholder-red-200 text-red-900',
        success:
          'py-1.5 border-green-300 bg-green-50 placeholder-gray-400 text-green-900',
        tableEntry:
          'py-1 w-auto px-2 placeholder-gray-500 border border-gray-400 sm:text-base -mt-1 cursor-pointer focus:border-gray-700 focus:outline-none',
      },
    },
  },

  TRichSelect: {
    component: TRichSelect,
    props: {
      fixedClasses: {
        wrapper: 'relative',
        buttonWrapper: 'inline-block relative w-full app-form-reset',
        selectButton:
          'w-full flex text-left justify-between items-center app-form-reset',
        selectButtonLabel: 'block truncate',
        selectButtonTagWrapper: 'flex flex-wrap overflow-hidden',
        selectButtonTag:
          'bg-blue-500 block disabled:cursor-not-allowed disabled:opacity-50 duration-100 ease-in-out focus:border-blue-500 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50 rounded shadow-sm text-sm text-white transition white-space-no m-0.5 max-w-full overflow-hidden h-8 flex items-center',
        selectButtonTagText: 'px-3',
        selectButtonTagDeleteButton:
          '-ml-1.5 h-full hover:bg-blue-600 hover:shadow-sm inline-flex items-center px-2 transition',
        selectButtonTagDeleteButtonIcon: 'w-3 h-3',
        selectButtonPlaceholder: 'block truncate',
        selectButtonIcon: 'fill-current flex-shrink-0 ml-1 h-4 w-4',
        selectButtonClearButton:
          'flex flex-shrink-0 items-center justify-center absolute right-0 top-0 m-2 h-6 w-6',
        selectButtonClearIcon: 'fill-current h-3 w-3',
        dropdown: 'absolute w-full z-10',
        dropdownFeedback: '',
        loadingMoreResults: '',
        optionsList: 'overflow-auto',
        searchWrapper: 'inline-block w-full',
        searchBox: 'inline-block w-full app-form-reset',
        option: 'cursor-pointer',
        disabledOption: 'opacity-50 cursor-not-allowed',
        highlightedOption: 'cursor-pointer',
        selectedOption: 'cursor-pointer',
        selectedHighlightedOption: 'cursor-pointer',
        optionContent: '',
        optionLabel: 'truncate block',
        selectedIcon: 'fill-current h-4 w-4',
        enterClass: '',
        enterActiveClass: '',
        enterToClass: '',
        leaveClass: '',
        leaveActiveClass: '',
        leaveToClass: '',
      },
      classes: {
        wrapper: '',
        buttonWrapper: '',
        selectButton:
          'bg-white border border-gray-400 rounded px-4 py-1.5 focus:border-gray-700',
        selectButtonLabel: '',
        selectButtonTagWrapper: '-mx-2 -my-2.5 py-1 pr-8',
        selectButtonTag:
          'bg-blue-500 block disabled:cursor-not-allowed disabled:opacity-50 duration-100 ease-in-out focus:border-blue-500 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50 rounded shadow-sm text-sm text-white transition white-space-no m-0.5 max-w-full overflow-hidden h-8 flex items-center',
        selectButtonTagText: 'px-3',
        selectButtonTagDeleteButton:
          '-ml-1.5 h-full hover:bg-blue-600 hover:shadow-sm inline-flex items-center px-2 transition',
        selectButtonTagDeleteButtonIcon: '',
        selectButtonPlaceholder: 'text-gray-400',
        selectButtonIcon: 'text-gray-600',
        selectButtonClearButton: 'hover:bg-blue-100 text-gray-500 rounded',
        selectButtonClearIcon: '',
        dropdown: 'rounded bg-white shadow',
        dropdownFeedback: 'text-sm text-gray-400',
        loadingMoreResults: 'text-sm text-gray-400',
        optionsList: '',
        searchWrapper: 'p-2 placeholder-gray-400',
        searchBox: 'p-2 bg-gray-200 text-sm rounded border',
        optgroup: 'text-gray-500 uppercase text-xs py-1 px-2 font-semibold',
        option: '',
        highlightedOption: 'bg-blue-100',
        selectedOption: 'font-semibold bg-gray-100',
        selectedHighlightedOption: 'bg-blue-100 font-semibold',
        optionContent: 'flex justify-between items-center p-2 cursor-pointer',
        optionLabel: 'truncate block',
        selectedIcon: '',
        enterClass: 'opacity-0',
        enterActiveClass: 'opacity-0 transition ease-out duration-100',
        enterToClass: 'opacity-100',
        leaveClass: 'transition ease-in opacity-100',
        leaveActiveClass: '',
        leaveToClass: 'opacity-0 duration-75',
      },
      variants: {
        danger: {
          selectButton:
            'border-red-600 text-red-600 border rounded p-1.5 focus:outline-none focus:shadow-none',
          selectButtonPlaceholder: 'text-red-400',
          selectButtonClearButton: 'hover:bg-red-200 text-red-500 rounded',
        },
        success: {
          selectButton:
            'border-green-500 text-green-500 bg-green-100 border rounded p-2 focus:outline-none focus:shadow-outline',
          selectButtonPlaceholder: 'text-green-400',
          selectButtonClearButton: 'hover:bg-green-200 text-green-500 rounded',
        },
        readonly: {
          selectButton:
            'bg-oLightestGray text-oLightGray placeholder-oLightGray border border-gray-400 rounded p-1.5 focus:outline-none focus:shadow-none',
          selectButtonPlaceholder: 'placeholder-oLightGray',
          selectButtonClearButton: 'hover:bg-green-200 text-green-500 rounded',
        },
        analyticsOrg: {
          selectButton:
            'text-oDark placeholder-oDark justify-start-important font-medium text-28px border-transparent shadow-none  rounded p-2 focus:outline-none focus:shadow-none',
          selectButtonPlaceholder: 'placeholder-oLightGray',
          selectButtonClearButton: 'hover:bg-green-200 text-green-500 rounded',
          dropdown: 'rounded-xs bg-white shadow',
        },
        fleetDropdown: {
          selectButton:
            'text-oDark placeholder-oDark justify-start-important font-medium text-lg border-transparent shadow-none  rounded p-2 focus:outline-none focus:shadow-none',
          selectButtonPlaceholder: 'placeholder-oLightGray',
          selectButtonClearButton: 'hover:bg-green-200 text-green-500 rounded',
          dropdown: 'rounded-xs bg-white shadow',
        },
      },
    },
  },

  //   TTag,

  TRadioUnwrapped: {
    component: TRadio,
    props: {
      fixedClasses:
        'form-radio app-form-reset cursor-pointer transition duration-150 ease-in-out disabled:opacity-50 disabled:cursor-not-allowed',
      classes: 'text-oDark border-oDark cursor-pointer focus:border-oDark',
      variants: {
        error: 'text-oRed border-red-300',
        success: 'text-oGreen border-green-300',
        info: 'text-blue-500 border-blue-300',
      },
    },
  },

  TRadio: {
    component: TRadio,
    props: {
      fixedClasses: {
        wrapper: 'flex items-center w-full cursor-pointer my-2',
        wrapperChecked: 'flex items-center w-full cursor-pointer my-2',
        label: 'mx-3 select-none text-14px text-oGray',
        labelChecked: 'mx-3 select-none text-14px text-oDark',
        inputWrapper: 'inline-flex',
        inputWrapperChecked: 'inline-flex',
        input:
          'form-radio app-form-reset cursor-pointer transition duration-150 ease-in-out',
      },
      classes: {
        wrapper: '',
        wrapperChecked: '',
        input: 'text-oDark border-oDark cursor-pointer focus:border-oDark',
        inputWrapper: '',
        inputWrapperChecked: '',
        label: 'font-semibold',
        labelChecked: 'font-semibold',
      },
      variants: {
        red: {
          wrapper: '',
          wrapperChecked: '',
          input: 'text-oRed border-oRed focus:border-red-700',
          inputWrapper: '',
          inputWrapperChecked: '',
          label: '',
          labelChecked: '',
        },
        green: {
          wrapper: '',
          wrapperChecked: '',
          input: 'text-oGreen border-oGreen focus:border-green-700',
          inputWrapper: '',
          inputWrapperChecked: '',
          label: '',
          labelChecked: '',
        },
        orange: {
          wrapper: '',
          wrapperChecked: '',
          input: 'text-oOrange border-oGreen focus:border-orange-700',
          inputWrapper: '',
          inputWrapperChecked: '',
          label: '',
          labelChecked: '',
        },
        lightweight: {
          wrapper: '',
          wrapperChecked: '',
          input: 'text-oDark border-oDark cursor-pointer focus:border-oDark',
          inputWrapper: '',
          inputWrapperChecked: '',
          label: 'font-normal text-oGray',
          labelChecked: 'font-normal text-oDark',
        },
      },
    },
  },

  TRadioGroup: {
    component: TRadioGroup,
    props: {
      classes: {
        groupWrapper: 'flex flex-col',
        wrapper: 'my-1',
        wrapperChecked: 'my-1',
        label: 'mx-2 text-gray-600 font-medium text-14px cursor-pointer',
        labelChecked: 'mx-2 text-oDark  font-medium text-14px cursor-pointer',
        inputWrapper: '',
        inputWrapperChecked: '',
        input:
          'form-radio app-form-reset text-black border border-black cursor-pointer transition duration-100 ease-in-out shadow-sm focus:border-oDark disabled:opacity-50 disabled:cursor-not-allowed',
      },
      variants: {
        danger: {
          groupWrapper: 'flex flex-col',
          label: 'ml-2 text-red-500 text-14px',
          input:
            'text-red-500 transition duration-100 ease-in-out border-red-500 shadow-sm focus:border-blue-500 focus:ring-2 focus:ring-blue-500 focus:outline-none focus:ring-opacity-50 focus:ring-offset-0 disabled:opacity-50 disabled:cursor-not-allowed',
        },
        buttons: {
          groupWrapper: 'flex',
          label: '',
          labelChecked: '',
          wrapper:
            'mx-1 bg-white border border-gray-300 flex items-center px-4 py-2 rounded shadow-sm cursor-pointer focus:shadow-outline text-sm text-gray-700 hover:text-gray-500 leading-5 uppercase',
          wrapperChecked:
            'mx-1 bg-gray-100 border border-gray-300 flex items-center px-4 py-2 rounded shadow-inner cursor-pointer focus:shadow-outline text-sm text-gray-700 hover:text-gray-500 leading-5 uppercase',
          inputWrapper: '',
          inputWrapperChecked: '',
          input: 'absolute invisible',
        },
      },
    },
  },

  TCheckbox: {
    component: TCheckbox,
    props: {
      fixedClasses: {
        wrapper: 'flex items-center w-full cursor-pointer my-2',
        wrapperChecked: 'flex items-center w-full cursor-pointer my-2',
        input:
          'form-checkbox app-form-reset cursor-pointer text-oDark border-oDark transition duration-150 ease-in-out focus:shadow-none',
        inputWrapper: 'inline-flex',
        inputWrapperChecked: 'inline-flex',
        label: ' mt-px select-none mx-3',
        labelChecked: ' mt-px select-none mx-3',
      },
      classes: {
        wrapper: '',
        wrapperChecked: '',
        input: 'text-oDark border-oDark cursor-pointer focus:border-oDark',
        inputWrapper: '',
        inputWrapperChecked: '',
        label: 'text-oGray font-semibold text-14px mx-3',
        labelChecked: 'text-oDark font-semibold text-14px mx-3',
      },
      variants: {
        red: {
          wrapper: '',
          wrapperChecked: '',
          input: 'text-oRed border-oRed focus:border-red-700',
          inputWrapper: '',
          inputWrapperChecked: '',
          label: 'text-14px mx-3',
          labelChecked: 'text-14px mx-3',
        },
        green: {
          wrapper: '',
          wrapperChecked: '',
          input: 'text-oGreen border-oGreen focus:border-green-700',
          inputWrapper: '',
          inputWrapperChecked: '',
          label: 'text-14px mx-3',
          labelChecked: 'text-14px mx-3',
        },
        orange: {
          wrapper: '',
          wrapperChecked: '',
          input: 'text-oOrange border-oGreen focus:border-orange-700',
          inputWrapper: '',
          inputWrapperChecked: '',
          label: 'text-14px mx-3',
          labelChecked: 'text-14px mx-3',
        },
        lightweight: {
          wrapper: '',
          wrapperChecked: '',
          input: 'text-oDark border-oDark focus:border-oDark',
          inputWrapper: '',
          inputWrapperChecked: '',
          label: 'mx-3 font-normal select-none text-sm text-oGray mx-3',
          labelChecked: 'mx-3 font-normal select-none text-sm text-oDark mx-3',
        },
        lg: {
          // wrapper: '',
          // wrapperChecked: '',
          // input:
          //   'text-oDark border-oDark cursor-pointer focus:border-oDark w-4 h-4',
          // inputWrapper: '',
          // inputWrapperChecked: '',
          // label: 'text-14px',
          // labelChecked: 'text-14px',

          wrapper: 'flex items-center w-full cursor-pointer ',
          wrapperChecked: 'flex items-center w-full cursor-pointer',
          input:
            'form-checkbox app-form-reset cursor-pointer text-oBlack border-oBlack transition duration-150 ease-in-out focus:shadow-none',
          inputWrapper: 'inline-flex',
          inputWrapperChecked: 'inline-flex',
          label: 'mx-3 mt-px font-semibold select-none text-14px text-oGray',
          labelChecked:
            'mx-3 mt-px font-semibold select-none text-14px text-oBlack',
        },
        liveMapTitle: {
          wrapper: '',
          wrapperChecked: '',
          input:
            'text-oDark border-oDark cursor-pointer focus:border-oDark w-4 h-4',
          inputWrapper: '',
          inputWrapperChecked: '',
          label: 'text-14px font-bold',
          labelChecked: 'text-14px font-bold',
        },
        liveMap: {
          wrapper: 'ml-1 mt-px select-none text-xs',
          wrapperChecked: 'ml-1 mt-px select-none text-xs',
          input:
            'text-oDark border-oDark cursor-pointer focus:border-oDark w-4 h-4',
          inputWrapper: '',
          inputWrapperChecked: '',
          label: 'text-xs-important',
          labelChecked: 'text-xs-important',
        },
      },
    },
  },

  TCheckboxGroup: {
    component: TCheckboxGroup,
    props: {
      fixedClasses: {
        groupWrapper: 'flex flex-col',
        wrapper: 'flex items-center w-full cursor-pointer my-1',
        wrapperChecked: 'flex items-center w-full cursor-pointer my-1',
        input:
          'form-checkbox app-form-reset cursor-pointer text-oBlack border-oBlack transition duration-150 ease-in-out focus:shadow-none',
        inputWrapper: 'inline-flex',
        inputWrapperChecked: 'inline-flex',
        label: 'mx-3 mt-px font-semibold select-none text-14px text-oGray',
        labelChecked:
          'mx-3 mt-px font-semibold select-none text-14px text-oBlack',
      },
      classes: {
        groupWrapper: '',
        wrapper: '',
        wrapperChecked: '',
        input: 'text-oBlack border-oBlack focus:border-oDark',
        inputWrapper: '',
        inputWrapperChecked: '',
        label: '',
        labelChecked: '',
      },
      variants: {
        red: {
          wrapper: '',
          wrapperChecked: '',
          input: 'text-oRed border-oRed focus:border-red-700',
          inputWrapper: '',
          inputWrapperChecked: '',
          label: '',
          labelChecked: '',
        },
        green: {
          wrapper: '',
          wrapperChecked: '',
          input: 'text-oGreen border-oGreen focus:border-green-700',
          inputWrapper: '',
          inputWrapperChecked: '',
          label: '',
          labelChecked: '',
        },
        orange: {
          wrapper: '',
          wrapperChecked: '',
          input: 'text-oOrange border-oGreen focus:border-orange-700',
          inputWrapper: '',
          inputWrapperChecked: '',
          label: '',
          labelChecked: '',
        },
        buttons: {
          groupWrapper: 'flex',
          label: '',
          labelChecked: '',
          wrapper:
            'mx-1 my-1 bg-white border border-gray-300 flex items-center px-4 py-2 rounded shadow-sm cursor-pointer focus:shadow-outline text-sm text-gray-700 hover:text-gray-500 leading-5 uppercase',
          wrapperChecked:
            'mx-1 my-1 bg-gray-100 border border-gray-300 flex items-center px-4 py-2 rounded shadow-inner cursor-pointer focus:shadow-outline text-sm text-gray-700 hover:text-gray-500 leading-5 uppercase',
          inputWrapper: '',
          inputWrapperChecked: '',
          input: 'absolute invisible',
        },
      },
    },
  },

  TToggle: {
    component: TToggle,
    props: {
      fixedClasses: {
        wrapper:
          'relative inline-flex flex-shrink-0 cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:shadow-none',
        wrapperChecked:
          'relative inline-flex flex-shrink-0 cursor-pointer transition-colors ease-in-out duration-200 border-2 border-transparent focus:ring-2 focus:ring-transparent focus:outline-none focus:ring-opacity-50',
        wrapperDisabled:
          'relative inline-flex flex-shrink-0 cursor-pointer transition-colors ease-in-out duration-200',
        wrapperCheckedDisabled:
          'relative inline-flex flex-shrink-0 cursor-pointer transition-colors ease-in-out duration-200 border-2 border-transparent focus:ring-2 focus:ring-transparent focus:outline-none focus:ring-opacity-50',
        button:
          'inline-block absolute transform translate-x-0 transition ease-in-out duration-200 focus:outline-none focus:shadow-none',
        buttonChecked:
          'inline-block absolute transform translate-x-full transition ease-in-out duration-200 focus:outline-none focus:shadow-none',
        checkedPlaceholder: 'inline-block',
        uncheckedPlaceholder: 'inline-block',
      },
      classes: {
        wrapper: 'bg-gray-200 rounded-full border-2 border-transparent',
        wrapperChecked: 'bg-blue-500 rounded-full border-2 border-transparent',
        wrapperDisabled:
          'bg-gray-100 rounded-full border-2 border-transparent focus:ring-2 focus:ring-transparent focus:outline-none focus:ring-opacity-50',
        button:
          'h-5 w-5 rounded-full bg-white shadow  flex items-center justify-center text-gray-400 text-xs',
        buttonChecked:
          'h-5 w-5 rounded-full bg-white shadow  flex items-center justify-center text-blue-500 text-xs',
        checkedPlaceholder:
          'rounded-full w-5 h-5 flex items-center justify-center text-gray-500 text-xs',
        uncheckedPlaceholder:
          'rounded-full w-5 h-5 flex items-center justify-center text-gray-500 text-xs',
      },
      variants: {
        danger: {
          wrapperChecked:
            'bg-red-500 focus:outline-none focus:shadow-outline rounded-full border-2 border-transparent',
        },
        success: {
          wrapperChecked:
            'bg-green-500 focus:outline-none focus:shadow-outline rounded-full border-2 border-transparent',
        },
        box: {
          wrapper:
            'bg-gray-200 focus:outline-none focus:shadow-outline rounded-sm border-2 border-transparent',
          wrapperChecked:
            'bg-blue-500 focus:outline-none focus:shadow-outline rounded-sm border-2 border-transparent',
          button:
            'h-6 w-6 rounded-sm bg-white shadow  flex items-center justify-center text-gray-400 text-xs',
          buttonChecked:
            'h-6 w-6 rounded-sm bg-white shadow  flex items-center justify-center text-blue-500 text-xs',
          checkedPlaceholder:
            'rounded-sm h-6 w-6 flex items-center justify-center text-gray-500 text-xs',
          uncheckedPlaceholder:
            'rounded-sm h-6 w-6 flex items-center justify-center text-gray-500 text-xs',
        },
        lightAndDarkMode: {
          button:
            'h-5 w-5 rounded-full bg-gray-100 shadow  flex items-center justify-center text-gray-400 text-xs',
          buttonChecked:
            'h-5 w-5 rounded-full bg-oBlack shadow  flex items-center justify-center text-blue-500 text-xs',
          wrapperChecked:
            'bg-oDark focus:outline-none focus:shadow-outline rounded-full border-2 border-transparent',
        },
        lock: {
          wrapperChecked:
            'bg-green-500 focus:outline-none focus:shadow-outline rounded-full border-2 border-transparent',
          wrapperDisabled:
            'bg-green-500 focus:outline-none focus:shadow-outline rounded-full border-2 border-transparent',
          wrapperCheckedDisabled:
            'bg-green-500 focus:outline-none focus:shadow-outline rounded-full border-2 border-transparent',
          button:
            'h-5 w-5 rounded-full bg-white shadow flex-important items-center justify-center text-oGray text-xs fas fa-unlock',
          buttonChecked:
            'h-5 w-5 rounded-full bg-white shadow flex-important items-center justify-center text-oGreen text-xs fas fa-lock',
        },
      },
    },
  },

  TButton: {
    component: TButton,
    props: {
      fixedClasses:
        'flex items-center justify-center px-4 w-auto h-36px font-medium text-14px leading-normal rounded uppercase cursor-pointer transition ease-in-out duration-150 focus:outline-none ',
      classes:
        'text-gray-100 bg-gray-900 border border-transparent hover:bg-gray-800 hover:border-gray-900 focus:bg-gray-700',
      variants: {
        secondary:
          'text-gray-700 bg-gray-200 border border-transparent hover:border-gray-900 hover:text-gray-900 hover:bg-gray-100 focus:bg-gray-300',
        gray:
          'text-gray-700 bg-gray-200 border border-transparent hover:border-gray-900 hover:text-gray-900 hover:bg-gray-100 focus:bg-gray-300',
        green:
          'text-white bg-oGreen border border-transparent hover:border-green-600 hover:text-white hover:bg-green-500 focus:bg-green-500',
        teal:
          'text-white bg-oTeal border border-transparent hover:border-teal-600 hover:text-white hover:bg-teal-500 focus:bg-teal-500',
        custom: '',
      },
    },
  },

  //   TCard,

  //   TAlert,

  TTable: {
    component: TTable,
    props: {
      classes: {
        table: 'shadow min-w-full divide-y divide-gray-200',
        tbody: 'bg-white divide-y divide-gray-200',
        td: 'px-6 py-4 whitespace-no-wrap text-sm leading-5 text-gray-700',
        theadTh:
          'px-6 py-3 border-b border-gray-200 bg-gray-100 text-left text-xs leading-4 font-medium text-gray-700 uppercase tracking-wider',
      },
      variants: {
        thin: {
          td: 'p-1 whitespace-no-wrap text-sm leading-4 text-gray-700',
          theadTh:
            'p-1 border-b border-gray-200 bg-gray-100 text-left text-xs leading-4 font-medium text-gray-700 uppercase tracking-wider',
        },
      },
    },
  },

  TPagination: {
    component: TPagination,
    props: {
      classes: {
        wrapper:
          'table border-collapse text-center bg-white mx-auto md:mx-0 mt-2 shadow-sm',
        element:
          'w-8 h-8 border table-cell border-gray-200 table-cell hover:border-blue-100',
        activeElement:
          'w-8 h-8 border border-gray-200 border-blue-500 table-cell hover:border-blue-600',
        disabledElement: 'w-8 h-8 border border-gray-200 table-cell',
        ellipsisElement: 'w-8 h-8 border border-gray-200 hidden md:table-cell',
        activeButton:
          'bg-blue-500 w-full h-full text-white hover:bg-blue-600 transition duration-100 ease-in-out focus:ring-2 focus:ring-blue-500 focus:outline-none focus:ring-opacity-50',
        disabledButton:
          'opacity-25 w-full h-full cursor-not-allowed transition duration-100 ease-in-out',
        button:
          'hover:bg-blue-100 w-full h-full transition duration-100 ease-in-out focus:ring-2 focus:ring-blue-500 focus:outline-none focus:ring-opacity-50',
        ellipsis: '',
      },
      variants: {
        rounded: {
          wrapper: 'bg-white mx-auto text-center flex space-x-2',
          element: 'w-8 h-8 rounded-full',
          activeElement: 'w-8 h-8 rounded-full',
          disabledElement: 'w-8 h-8 rounded-full',
          ellipsisElement: 'w-8 h-8 rounded-full hidden md:inline',
          activeButton:
            'border border-blue-500 bg-blue-500 w-full h-full rounded-full text-white hover:bg-blue-600 transition duration-100 ease-in-out focus:ring-2 focus:ring-blue-500 focus:outline-none focus:ring-opacity-50',
          disabledButton:
            'border border-gray-200  opacity-25 w-full h-full rounded-full cursor-not-allowed transition duration-100 ease-in-out',
          button:
            'border border-gray-200 hover:bg-blue-100 hover:border-blue-100 rounded-full w-full h-full transition duration-100 ease-in-out focus:ring-2 focus:ring-blue-500 focus:outline-none focus:ring-opacity-50',
          ellipsis: '',
        },
      },
    },
  },

  TModal: {
    component: TModal,
    props: {
      fixedClasses: {
        overlay:
          'z-210 inset-0 w-full h-full fixed bg-opacity-50 transition-all duration-100 ease-in-out',
        wrapper:
          'z-220 relative overflow-y-auto mx-auto my-0 max-w-lg mt-12 transition-all duration-300 ease-in-out',
        modal: 'bg-white shadow overflow-hidden relative rounded-md',
        body: '',
        header: 'text-gray-700',
        footer: 'text-gray-700',
        close: 'absolute right-0 top-0 m-3 text-gray-700 hover:text-gray-600',
        closeIcon: 'h-5 w-5 fill-current',
      },
      classes: {
        overlay: 'bg-gray-500 ',
        wrapper: 'max-w-3xl',
        modal: '',
        body: '',
        header: 'text-gray-700 border-b',
        footer: 'border-t ',
        close: 'text-gray-700 hover:text-gray-600',
        closeIcon: '',
      },
      variants: {
        'subscription': {
          overlay: 'bg-gray-500 ',
          wrapper: 'max-w-xl max-h-5/6 sb-farhan my-auto',
          modal: '',
          body: '',
          header: 'text-gray-700 w-full max-w-xl',
          footer: 'border-t ',
          close: 'text-gray-700 hover:text-gray-600',
          closeIcon: '',
        },
        'transaction': {
          overlay: 'bg-gray-500 ',
          wrapper: 'max-w-lg max-h-5/6 sb-farhan my-auto rounded',
          modal: '',
          body: '',
          header: 'text-gray-700 w-full max-w-xl',
          footer: 'border-t ',
          close: 'text-gray-700 hover:text-gray-600',
          closeIcon: '',
        },
        'danger': {
          overlay: 'bg-red-500',
          header: 'border-red-100 text-red-700 border-b',
          footer: 'border-red-100 bg-red-100 border-t  ',
          close: 'text-red-700 hover:text-red-600',
        },
        'clean': {
          modal: 'rounded shadow-lg',
          footer: 'bg-gray-100 ',
          body: 'p-4 text-sm text-gray-700',
          close:
            'bg-blue-100 p-1 flex items-center justify-center leading-normal rounded-full text-blue-80 hover:bg-blue-200',
        },
        'popup': {
          wrapper: 'mt-20 md:mt-72',
          modal: 'rounded shadow-lg',
          footer: 'bg-gray-100 ',
          body: 'p-4 text-sm text-gray-700',
          close:
            'bg-blue-100 p-1 flex items-center justify-center leading-normal rounded-full text-blue-80 hover:bg-blue-200',
        },
        'popupAlt': {
          wrapper: 'mt-20 max-w-xl',
          modal: 'rounded shadow-lg',
          footer: ' ',
          body: 'text-sm text-gray-700',
          close:
            'bg-blue-100 p-1 flex items-center justify-center leading-normal rounded-full text-blue-80 hover:bg-blue-200',
        },
        'vehicleLocation': {
          wrapper: 'max-w-3xl max-h-11/12 sb-farhan my-auto',
          modal: 'rounded shadow-lg',
          footer: ' ',
          body: 'text-sm text-gray-700',
        },
        'confirmation': {
          wrapper: 'max-w-lg max-h-11/12 sb-farhan my-auto',
          modal: 'rounded-lg shadow-lg',
          footer: ' ',
          body: 'text-sm text-gray-700',
        },
        'xl': {
          overlay: 'bg-gray-500 ',
          wrapper:
            'max-w-xl relative max-h-5/6 vsm_light-theme SB-FarhanShares my-auto',
          modal: '',
          body: '',
          header: 'text-gray-700 w-full max-w-xl',
          footer: 'border-t ',
          close: 'text-gray-700 hover:text-gray-600',
          closeIcon: '',
        },
        '2xl': {
          overlay: 'bg-gray-500 ',
          wrapper:
            'max-w-2xl relative max-h-5/6 vsm_light-theme SB-FarhanShares my-auto',
          modal: '',
          body: '',
          header: 'text-gray-700 w-full max-w-xl',
          footer: 'border-t ',
          close: 'text-gray-700 hover:text-gray-600',
          closeIcon: '',
        },
        'lg': {
          overlay: 'bg-gray-500 ',
          wrapper: 'max-w-lg',
        },
      },
    },
  },

  TDialog: {
    component: TDialog,
    props: {
      fixedClasses: {
        overlay:
          'overflow-auto scrolling-touch left-0 top-0 bottom-0 right-0 w-full h-full fixed z-40 bg-opacity-50',
        wrapper: 'relative mx-auto',
        modal: 'overflow-visible relative ',
        close:
          'flex items-center justify-center  rounded-full absolute right-0 top-0 -m-3 h-8 w-8 transition duration-100 ease-in-out focus:ring-2 focus:ring-blue-500 focus:outline-none focus:ring-opacity-50',
        dialog: 'overflow-visible relative',
        iconWrapper:
          'flex flex-shrink-0 h-12 items-center justify-center rounded-full w-12 mx-auto',
        icon: 'w-6 h-6',
        okButton:
          'block px-4 py-2 text-white transition duration-100 ease-in-out border border-transparent rounded shadow-sm focus:border-blue-500 focus:ring-2 focus:ring-blue-500 focus:outline-none focus:ring-opacity-50 disabled:opacity-50 disabled:cursor-not-allowed w-full max-w-xs',
        buttons: 'p-3 flex space-x-4 justify-center bg-gray-100 rounded-b',
      },
      classes: {
        close: 'bg-gray-100 text-gray-600 hover:bg-gray-200',
        closeIcon: 'fill-current h-4 w-4',
        overlay: 'bg-black',
        wrapper: 'z-50 max-w-lg px-3 py-12',
        dialog: 'bg-white shadow rounded text-left',
        body: 'p-3 space-y-3',
        buttons: 'bg-gray-100',
        iconWrapper: 'bg-gray-100',
        icon: 'text-gray-500',
        content: 'w-full flex justify-center flex-col',
        titleWrapper: '',
        title: 'text-lg font-semibold text-center',
        textWrapper: 'text-center w-full',
        text: '',
        cancelButton:
          'relative flex items-center justify-center text-center px-4 font-semibold text-14px leading-normal rounded transition ease-in-out duration-150 uppercase w-full max-w-xs cursor-pointer focus:outline-none bg-gray-200 border border-transparent hover:border-gray-900 hover:text-gray-900 focus:bg-gray-300',
        okButton:
          'text-gray-100 bg-gray-900 border border-transparent hover:text-white hover:bg-gray-800 hover:border-gray-800 focus:bg-gray-700',
        inputWrapper: 'mt-3 flex items-center space-x-3',
        input:
          'block w-full px-3 py-2 text-black placeholder-gray-400 transition duration-100 ease-in-out bg-white border border-gray-300 rounded shadow-sm focus:border-blue-500 focus:ring-2 focus:ring-blue-500 focus:outline-none focus:ring-opacity-50 disabled:opacity-50 disabled:cursor-not-allowed w-full',
        select:
          'block w-full px-3 py-2 text-black placeholder-gray-400 transition duration-100 ease-in-out bg-white border border-gray-300 rounded shadow-sm focus:border-blue-500 focus:ring-2 focus:ring-blue-500 focus:outline-none focus:ring-opacity-50  disabled:opacity-50 disabled:cursor-not-allowed w-full',
        radioWrapper: 'flex items-center space-x-2',
        radio:
          'text-blue-500 transition duration-100 ease-in-out border-gray-300 shadow-sm focus:border-blue-500 focus:ring-2 focus:ring-blue-500 focus:outline-none focus:ring-opacity-50 focus:ring-offset-0  disabled:opacity-50 disabled:cursor-not-allowed',
        radioText: '',
        checkboxWrapper: 'flex items-center space-x-2',
        checkbox:
          'text-blue-500 transition duration-100 ease-in-out border-gray-300 rounded shadow-sm focus:border-blue-500 focus:ring-2 focus:ring-blue-500 focus:outline-none focus:ring-opacity-50 focus:ring-offset-0  disabled:opacity-50 disabled:cursor-not-allowed',
        checkboxText: '',
        errorMessage: 'text-red-500 block text-sm',
        busyWrapper:
          'absolute bg-opacity-50 bg-white flex h-full items-center justify-center left-0 top-0 w-full',
        busyIcon: 'animate-spin h-6 w-6 fill-current text-gray-500',
        overlayEnterClass: '',
        overlayEnterActiveClass: 'opacity-0 transition ease-out duration-100',
        overlayEnterToClass: 'opacity-100',
        overlayLeaveClass: 'transition ease-in opacity-100',
        overlayLeaveActiveClass: '',
        overlayLeaveToClass: 'opacity-0 duration-75',
        enterClass: '',
        enterActiveClass: '',
        enterToClass: '',
        leaveClass: '',
        leaveActiveClass: '',
        leaveToClass: '',
      },
      variants: {
        danger: {
          overlay: 'bg-red-100',
          okButton: 'bg-red-500 hover:bg-red-600',
          iconWrapper: 'bg-red-50',
          buttons: 'bg-red-50',
          icon: 'text-red-500',
        },
        horizontal: {
          body: 'p-3 flex space-x-3',
          title: 'text-lg font-semibold',
        },
      },
    },
  },

  TDropdown: {
    component: TDropdown,
    props: {
      fixedClasses: {
        wrapper: 'flex flex-col',
        // remove border?
        button:
          'flex items-center transition duration-100 ease-in-out border border-transparent app-form-reset disabled:opacity-50 disabled:cursor-not-allowed',
        dropdownWrapper: 'relative z-20',
        dropdown: 'absolute rounded', // recommended classes: left-*/right-*, origin-*, mt-*, shadow-*
        enterClass: 'transform opacity-0 scale-95',
        enterActiveClass: 'transition ease-out duration-100 opacity-0 scale-95',
        enterToClass: 'opacity-100 scale-100',
        leaveClass: 'transform opacity-100 scale-100',
        leaveActiveClass: 'transition transform ease-in duration-75',
        leaveToClass: 'opacity-0 scale-95',
      },
      classes: {
        button: 'px-4 py-2 border rounded hover:text-gray-700',
        dropdown: 'origin-top-right right-0 w-56 bg-white shadow',
      },
      variants: {
        danger: {
          button:
            'px-4 py-2 flex items-center border rounded bg-red-100 border-red-600 text-red-500 hover:text-red-400',
          dropdown:
            'origin-top-right absolute right-0 w-56 rounded-md shadow bg-red-100',
        },
        calendar: {
          button:
            'flex items-center justify-between w-56 h-12 px-2 mx-2 text-sm text-gray-700 transition duration-150 ease-in-out bg-white border rounded-full shadow-md focus:outline-none focus:shadow-solid',
          // todo: make responsive
          dropdown:
            'origin-top-right absolute right-0 w-850px h-auto rounded-md shadow bg-white',
        },
        smActions: {
          button: 'px-4 py-2 border rounded text-gray-700',
          dropdown: 'origin-top-right right-0 w-40 rounded-md shadow-lg',
        },
        smActionsLeft: {
          button: 'px-4 py-2 border rounded text-gray-700',
          dropdown: 'origin-top-left left-0 w-40 rounded-md shadow-lg',
        },
        fullLeft: {
          button: 'px-4 py-2 border rounded text-gray-700',
          dropdown: 'origin-top-left left-0 w-full rounded-md shadow-lg',
        },
        // todo: move filter menu & pill class to somewhere global
        filterMenu: {
          button:
            'px-4 py-1 border rounded-full bg-gray-200 text-gray-700 bg-gray-200 border hover:bg-white focus:shadow-md focus:bg-white group',
          dropdown:
            'origin-top-left left-0 mt-1 bg-white border shadow-md  rounded-md fs-table-filter-dropdown-menu',
        },
        filterPill: {
          button:
            'px-4 py-1 border rounded-full bg-gray-200 text-gray-700 bg-gray-200 border hover:bg-white focus:shadow-md focus:bg-white group',
          dropdown:
            'origin-top-left left-0 mt-1 bg-white border rounded-md shadow-md min-w-filter-pill',
        },
      },
    },
  },

  //   TDatepicker,
}

Vue.use(VueTailwind, OTORideTheme)
