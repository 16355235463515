export const NavigationConfig = {
  sideMenu: [
    {
      header: true,
      title: 'Main Navigation',
      hidden: true,
      hiddenOnCollapse: true,
    },

    // Dashboard
    {
      title: 'Dashboard',
      permId: 'Dashboard',
      icon: 'fa fa-chart-line',
      href: '/dashboard',
    },
    {
      title: 'Vehicles',
      permId: 'Vehicles',
      icon: 'fa fa-bicycle',
      child: [
        {
          href: '/purchased-vehicles',
          title: 'Purchased Vehicles',
        },
        {
          href: '/primary-orders',
          title: 'Buy Vehicles',
        },
      ],
    },

    // {
    //   title: 'Vehicles',
    //   permId: 'Vehicles',
    //   icon: 'fa fa-bicycle',
    //   href: '/vehicles',
    // },
    // {
    //   title: 'Buy',
    //   permId: 'buy',
    //   icon: 'fa fa-bicycle',
    //   href: '/primary-orders',
    // },

    // Transaction

    {
      title: 'Transactions',
      permId: 'Transactions',
      icon: 'fa fa-credit-card',
      child: [
        {
          href: '/purchase',
          title: 'Purchase',
        },
        {
          href: '/payout',
          title: 'Payout',
        },
      ],
    },
    // Settings
    {
      title: 'Settings',
      permId: 'Settings',
      icon: 'fa fa-cog',
      href: '/settings/profile',
    },
  ],
}
