export const VehicleConfig = {
  api: {
    // get
    index: '/dashboard/vehicles/',
    // get
    single: (id = 'uuid') => {
      return `/dashboard/vehicles/${id}/`
    },
    // post
    create: '/dashboard/vehicles/',
    // patch
    update: (id = 'uuid') => {
      return `/dashboard/vehicles/${id}/`
    },
    // patch
    updateGeneralFLags: (id = 'uuid') => {
      return `/dashboard/vehicles/${id}/general-flags/`
    },
    // patch
    updateOperationalFLags: (id = 'uuid') => {
      return `/dashboard/vehicles/${id}/operational-flags/`
    },
    // patch
    updateControlFLags: (id = 'uuid') => {
      return `/dashboard/vehicles/${id}/control-flags/`
    },
    // patch
    updateStatusFLags: (id = 'uuid') => {
      return `/dashboard/vehicles/${id}/status-flags/`
    },
    // post
    ringAlarm: (id = 'uuid') => {
      return `/dashboard/vehicles/${id}/ring-alarm/`
    },
    // post
    unlockBattery: (id = 'uuid') => {
      return `/dashboard/vehicles/${id}/unlock-battery/`
    },
    // post
    restartIot: (id = 'uuid') => {
      return `/dashboard/vehicles/${id}/restart-iot/`
    },
    // get
    logs: (id = 'uuid') => {
      return `/dashboard/vehicles/${id}/logs/`
    },
    iotLogs: (id = 'uuid') => {
      return `/dashboard/vehicles/${id}/iot-logs/`
    },
    powerLevel: (vehicleId, startDate) => {
      return `/dashboard/vehicles/${vehicleId}/power-level-chart/?start_date=${startDate}`
    },
    healthChart: (vehicleId, startDate, endDate) => {
      return `/dashboard/vehicles/${vehicleId}/health-charts/?start_date=${startDate}&end_date=${endDate}`
    },
    status: {
      current: (vehicleId) => {
        return `/dashboard/vehicles/${vehicleId}/status/`
      },
      // post
      lock: (vehicleId) => {
        return `/dashboard/vehicles/${vehicleId}/lock/`
      },
      // post
      unlock: (vehicleId) => {
        return `/dashboard/vehicles/${vehicleId}/unlock/`
      },
      // post
      forceLock: (vehicleImei, vehicleType) => {
        return `/dashboard/${vehicleType}/${vehicleImei}/force-lock/`
      },
      // post
      forceUnlock: (vehicleImei, vehicleType) => {
        return `/dashboard/${vehicleType}/${vehicleImei}/force-unlock/`
      },
      // post
      forceSync: (vehicleId) => {
        return `/dashboard/vehicles/${vehicleId}/force-status-sync/`
      },
    },
    reload: {
      // get
      status: (vehicleId) => {
        return `/dashboard/vehicles/${vehicleId}/reload-status/`
      },
      bluetoothKey: (vehicleId) => {
        return `/dashboard/vehicles/${vehicleId}/reload-ble-key/`
      },
      bluetoothMac: (vehicleId) => {
        return `/dashboard/vehicles/${vehicleId}/reload-ble-mac/`
      },
    },
  },
  events: {
    name: 'vehicles',
    // refresh-index-data
    refresh: `rid-vehicle`,
    // slideover-right
    sorId: 'vehicle',
    sorOpen: 'sor-open-vehicle',
    sorClose: 'sor-close-vehicle',
    sorToggle: 'sor-toggle-vehicle',
    // editing-data
    editingData: 'edit-vehicle-data',
    editingStep1Data: 'edit-vehicle-step-1-data',
    editingStep2Data: 'edit-vehicle-step-2-data',

    succeededStep1: 'succeeded-step-1',
    succeededStep2: 'succeeded-step-2',
    // viewing-data
    viewingData: 'view-vehicle-data',
    flagData: 'vehicle-flag-data',
  },
}
