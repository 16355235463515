import moment from 'moment'

export * from './getUTCAwareTime'
export * from './parseSO8601Duration'

const DATE_OUT_FORMAT = 'D MMM'
const DATETIME_OUT_FORMAT = 'D MMM, h:m a'

function momentSmartParse(raw) {
  if (moment(raw).isValid()) {
    return moment(raw)
  }

  const acceptedFormats = [
    'DD/MM/YYYY',
    'DD-MM-YYYY',
    'DDMMYYYY',
    'MMDDYYYY',
    'MM-DD-YYYY',
    'MM/DD/YYYY',
    'YYYYMMDD',
    'YYYY-MM-DD',
    'YYYY/MM/DD',
    'YYYYMMDDhhmmss',
  ]

  for (let i = 0; i < acceptedFormats.length; i++) {
    const item = acceptedFormats[i]

    const m = moment(raw, item)

    if (m.isValid()) {
      return m
    }
  }

  return null
}

function getFormattedMomentStr(m, format) {
  return m.format(format)
}

export function getFormattedDate(d, format) {
  const m = momentSmartParse(d)

  return m ? getFormattedMomentStr(m, format || DATE_OUT_FORMAT) : null
}

export function getFormattedDateTime(dt, format) {
  const m = momentSmartParse(dt)

  return m ? getFormattedMomentStr(m, format || DATETIME_OUT_FORMAT) : null
}

export function getDateDifference(d1, d2) {
  const m1 = momentSmartParse(d1)

  if (!m1) {
    return null
  }

  const m2 = momentSmartParse(d2)

  if (!m2) {
    return null
  }

  return m1 > m2
    ? moment.duration(m1.diff(m2)).humanize()
    : moment.duration(m2.diff(m1)).humanize()
}

export function getTimeAgo(dt) {
  const m = momentSmartParse(dt)

  if (!m) {
    return null
  }

  const diff = moment.duration(moment().diff(m))

  const days = diff.days()
  const hours = diff.hours()
  const minutes = diff.minutes()
  const seconds = diff.seconds()

  if (days) {
    return `${days}d ${hours}h`
  }

  if (hours) {
    return `${hours}h ${minutes}m`
  }

  if (minutes) {
    return `${minutes}m ${seconds}s`
  }

  return `${seconds}s`
}

export function getHumanReadableDuration(s, unit) {
  const diff = moment.duration(s, unit || 'seconds')

  const days = diff.days()
  const hours = diff.hours()
  const minutes = diff.minutes()
  const seconds = diff.seconds()
  console.log('SEC', s, 'TIME', `${days}d ${hours}h ${minutes}m ${seconds}s`)
  if (days) {
    return `${days}d ${hours}h ${minutes}m ${seconds}s`
  }

  if (hours) {
    return `${hours}h ${minutes}m ${seconds}s`
  }

  if (minutes) {
    return `${minutes}m ${seconds}s`
  }

  return `${seconds}s`
}

export function getTimeAgoInSeconds(dt) {
  const m = momentSmartParse(dt)

  if (!m) {
    return null
  }

  const diff = moment.duration(moment().diff(m, 'seconds'), 'seconds')

  return diff.asSeconds()
}
