import ReconnectingWebsocket from 'reconnectingwebsocket'
import { default as SocketConfig } from '@/config/socket.config'

export async function openRWS({
  channel,
  onMessage = (msg) => console.log(msg),
}) {
  const token = localStorage.getItem('token') || null
  if (!token) {
    console.log({ openWSL: 'Unauthenticated! No token found!' })
    return false
  }

  const host = SocketConfig.getChannel({ name: channel, token })

  if (!host) {
    console.log({ openWSL: `No such channel: ${channel}` })
    return false
  }

  const rws = new ReconnectingWebsocket(host, null, SocketConfig.options())

  rws.onopen = function() {
    console.log('RWS opened.')
  }

  rws.onclose = function() {
    console.log('RWS closed.')
  }

  rws.onmessage = onMessage

  return rws
}

// rws is the instance reference
export async function closeRWS(rws) {
  if (rws) {
    await rws.close(4003, 'oto system closed the socket')
    return
  }
}
