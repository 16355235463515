import Vue from 'vue'
// Vue-google-maps
import * as VueGoogleMaps from 'vue2-google-maps'

Vue.use(VueGoogleMaps, {
  load: {
    // todo: keep the key in the env
    key: 'AIzaSyDAM863ajWTCZg6n9ve4zVwnMLAAGY3nYA',
    libraries: 'drawing,geometry,places,visualization,directions',
    // OR: libraries: 'places,drawing'
    // OR: libraries: 'places,drawing,visualization'

    // If you want to set the version, you can do so:
    v: '3.44',
  },

  // If you intend to programmatically custom event listener code
  // (e.g. `this.$refs.gmap.$on('zoom_changed', someFunc)`)
  // instead of going through Vue templates (e.g. `<GmapMap @zoom_changed="someFunc">`)
  // you might need to turn this on.

  // autobindAllEvents: false,

  // If you want to manually install components, e.g.
  // import {GmapMarker} from 'vue2-google-maps/src/components/marker'
  // Vue.component('GmapMarker', GmapMarker)
  // then set installComponents to 'false'.
  // If you want to automatically install all the components this property must be set to 'true':

  // installComponents: true,
})
