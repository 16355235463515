import states from './mail-template.states'
import getters from './mail-template.getters'
import mutations from './mail-template.mutations'
import actions from './mail-template.actions'

export default {
  namespaced: true,
  state: states,
  getters,
  mutations,
  actions,
}
