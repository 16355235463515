import Vue from 'vue'
import mitt from 'mitt'

/**
 * This is specially for XStepper component's event system.
 * It should not be used elsewhere.
 */
export const xStepperEmitter = mitt()

/**
 * This class is for maintaining states of XStepper component easily
 * This is not a npm plugin, rather a local plugin for the app.
 */
export class XStepperManager {
  constructor() {
    this.debug = false
    this.emitter = xStepperEmitter

    this.steppers = {}

    this.debug && console.log({ XStepperEvents: this.emitter.all })
  }

  defineSteps(id, steps) {
    this.emitter.emit(this.getEventName('defineSteps', id), { id, steps })
    // console.log('defineSend', this.getEventName('defineSteps', id), id, steps)
  }

  /**
   * Define id for navigation
   *
   * @param {string|number} id
   * @returns this
   */
  navigate(id) {
    this.id = id
    return this
  }

  next() {
    // hasNext() or hasPrev() is taken care in the component
    this.emitter.emit(this.getEventName('nextStep'))

    this.debug &&
      console.log({ XStepperNavigateNext: this.getEventName('nextStep') })
  }

  prev() {
    // hasNext() or hasPrev() is taken care in the component
    this.emitter.emit(this.getEventName('prevStep'))

    this.debug &&
      console.log({ XStepperNavigatePrev: this.getEventName('nextStep') })
  }

  to(step) {
    // strict or free mode based navigation is handled in the component
    this.emitter.emit(this.getEventName('jumpStep'), {
      navigateTo: step,
    })

    this.debug &&
      console.log({
        XStepperNavigateTo: this.getEventName('jumpStep'),
      })
  }

  getEventName(type, id = null) {
    const prefixMap = {
      nextStep: 'xs-next:',
      prevStep: 'xs-prev:',
      jumpStep: 'xs-jump:',
      changeStep: 'xs-change-step:',
      defineSteps: 'xs-define:',
    }

    if (prefixMap[type]) {
      const theId = id ? id : this.id
      return prefixMap[type].concat(theId)
    }

    this.debug && console.warn('xs-trying-to-get-unknown-event-type')
    return 'xs-trying-to-get-unknown-event-type'
  }
}

export const xStepper = new XStepperManager()
Vue.prototype.$xStepper = xStepper
