/*
example payload format
{
    step: number [required]
    data: object [optional]
}
*/

export default {
  namespaced: true,

  state: {
    // status (idle/busy/succeeded/failed)
    status: 'idle',
    // current step
    step: undefined,
    // steps data
    step1: null,
    step2: null,
    // current step that is being edited (undefined/number)
    editingStep: undefined,
    editingScooter: undefined,
    editingScooterId: undefined,
  },
  getters: {
    currentStep: (state) => {
      return state.step || 1
    },
    isEditing: (state) => {
      return !!state.editingStep
    },
    currentEditingStep: (state) => {
      return state.editingStep
    },
    currentEditingData: (state) => {
      if (state.currentEditingStep === 1) return state.step1
      if (state.currentEditingStep === 2) return state.step2
      if (state.currentEditingStep === 3) return state.step3
      return undefined
    },
    currenteditingScooterId: (state) => {
      return state.editingScooterId || ''
    },
  },
  mutations: {
    STATUS_IDLE(state) {
      state.status = 'idle'
    },
    STATUS_BUSY(state) {
      state.status = 'busy'
    },
    STATUS_FAILED(state) {
      state.status = 'failed'
    },
    STATUS_SUCCEEDED(state) {
      state.status = 'succeeded'
    },
    REMEMBER_STEP_DATA(state, payload) {
      let step = payload.step
      let data = payload.data

      if (step === 1) {
        state.step1 = data
      }
      if (step === 2) {
        state.step2 = data
      }
    },
    FORGET_STEP_DATA(state, payload) {
      let step = payload.step

      if (step === 1) {
        state.step1 = null
      }
      if (step === 2) {
        state.step2 = null
      }
    },
    FORGET_ALL_STEPS_DATA(state) {
      state.step1 = null
      state.step2 = null
    },
    REMEMBER_CURRENT_STEP(state, payload) {
      state.step = payload.step
    },
    FORGET_CURRENT_STEP(state) {
      state.step = undefined
    },
    REMEMBER_CURRENT_EDITING_STEP(state, payload) {
      state.editingStep = payload.step
    },
    FORGET_CURRENT_EDITING_STEP(state) {
      state.editingStep = undefined
    },
    REMEMBER_CURRENT_EDITING_SCOOTER_ID(state, payload) {
      state.editingScooterId = payload.data
    },
    FORGET_CURRENT_EDITING_SCOOTER_ID(state) {
      state.editingScooterId = undefined
    },
  },
  actions: {
    saveStep({ commit }, payload) {
      commit('STATUS_BUSY')

      commit('REMEMBER_CURRENT_STEP', payload)

      commit('STATUS_SUCCEEDED')
      commit('STATUS_IDLE')
    },
    // syntactic sugar for saveStep
    nextStep({ commit }, payload) {
      commit('STATUS_BUSY')

      commit('REMEMBER_CURRENT_STEP', payload)

      commit('STATUS_SUCCEEDED')
      commit('STATUS_IDLE')
    },
    forgetStep({ commit }) {
      commit('STATUS_BUSY')

      commit('FORGET_CURRENT_STEP')

      commit('STATUS_SUCCEEDED')
      commit('STATUS_IDLE')
    },
    saveEditingStep({ commit }, payload) {
      commit('STATUS_BUSY')

      commit('REMEMBER_CURRENT_EDITING_STEP', payload)

      commit('STATUS_SUCCEEDED')
      commit('STATUS_IDLE')
    },
    forgetEditingStep({ commit }) {
      commit('STATUS_BUSY')

      commit('FORGET_CURRENT_EDITING_STEP')

      commit('STATUS_SUCCEEDED')
      commit('STATUS_IDLE')
    },
    saveEditingScooterId({ commit }, payload) {
      commit('STATUS_BUSY')

      commit('REMEMBER_CURRENT_EDITING_SCOOTER_ID', payload)

      commit('STATUS_SUCCEEDED')
      commit('STATUS_IDLE')
    },
    forgetEditingScooterId({ commit }) {
      commit('STATUS_BUSY')

      commit('FORGET_CURRENT_EDITING_SCOOTER_ID')

      commit('STATUS_SUCCEEDED')
      commit('STATUS_IDLE')
    },
    saveStepData({ commit }, payload) {
      commit('STATUS_BUSY')

      commit('REMEMBER_STEP_DATA', payload)

      commit('STATUS_SUCCEEDED')
      commit('STATUS_IDLE')
    },
    forgetStepData({ commit }, payload) {
      commit('STATUS_BUSY')

      commit('FORGET_STEP_DATA', payload)

      commit('STATUS_SUCCEEDED')
      commit('STATUS_IDLE')
    },
    forgetAllStepsData({ commit }) {
      commit('STATUS_BUSY')

      commit('FORGET_ALL_STEPS_DATA')

      commit('STATUS_SUCCEEDED')
      commit('STATUS_IDLE')
    },
  },
}
