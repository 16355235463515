export function changeDateFormat(date) {
  //11:50 PM, 12 July 2020
  let result = ''
  var arr_month = new Array()
  arr_month[0] = 'January'
  arr_month[1] = 'February'
  arr_month[2] = 'March'
  arr_month[3] = 'April'
  arr_month[4] = 'May'
  arr_month[5] = 'June'
  arr_month[6] = 'July'
  arr_month[7] = 'August'
  arr_month[8] = 'September'
  arr_month[9] = 'October'
  arr_month[10] = 'November'
  arr_month[11] = 'December'

  if (date == '') {
    var now_body = new Date()
    let year = now_body.getFullYear()
    let month_idx = now_body.getMonth()
    let day = now_body.getDate()
    var month1 = arr_month[month_idx]

    let time = formatAMPM(now_body)

    result = time + ', ' + day + ' ' + month1 + ' ' + year
    return result
  } else {
    var time_body = new Date(date)

    let year = time_body.getFullYear()
    let month_idx = time_body.getMonth()
    let day = time_body.getDate()
    var month2 = arr_month[month_idx]

    let time = formatAMPM(time_body)

    result = time + ', ' + day + ' ' + month2 + ' ' + year
    return result
  }
}
export function changeDateFormat2(date) {
  //11:50 PM, 12 July 2020
  let result = ''
  var arr_month = new Array()
  arr_month[0] = 'Jan'
  arr_month[1] = 'Feb'
  arr_month[2] = 'Mar'
  arr_month[3] = 'Apr'
  arr_month[4] = 'May'
  arr_month[5] = 'Jun'
  arr_month[6] = 'Jul'
  arr_month[7] = 'Aug'
  arr_month[8] = 'Sep'
  arr_month[9] = 'Oct'
  arr_month[10] = 'Nov'
  arr_month[11] = 'Dec'

  var time_body = new Date(date)

  let year = time_body.getFullYear()
  let month_idx = time_body.getMonth()
  let day = time_body.getDate()
  var month2 = arr_month[month_idx]

  result = day + ' ' + month2 + ' ' + year
  return result
}

export function formatAMPM(date) {
  var hours = date.getHours()
  var minutes = date.getMinutes()
  var ampm = hours >= 12 ? 'PM' : 'AM'
  hours = hours % 12
  hours = hours ? hours : 12 // the hour '0' should be '12'
  minutes = minutes < 10 ? '0' + minutes : minutes
  var strTime = hours + ':' + minutes + ' ' + ampm
  return strTime
}

export function getDateFromOneToOther(start, end) {
  if (start != '' && end != '') {
    let date1 = new Date(start)
    let date2 = new Date(end)

    var Difference_In_Time = date2.getTime() - date1.getTime()
    var Difference_In_Days = Difference_In_Time / (1000 * 3600 * 24)

    var arr = []
    for (var i = 0; i < Difference_In_Days + 1; i++) {
      var date = new Date(date1.getTime() + 1000 * 3600 * 24 * i)
      var obj =
        date.getFullYear() +
        '-' +
        (date.getMonth() + 1 < 10
          ? '0' + (date.getMonth() + 1)
          : date.getMonth() + 1) +
        '-' +
        (date.getDate() < 10 ? '0' + date.getDate() : date.getDate())
      arr.push(obj)
    }
  }
  return arr
}

export function getLocalDateFromUTC(dateStr) {
  const date = new Date(dateStr)
  var localDate = new Date(
    date.getTime() + date.getTimezoneOffset() * 60 * 1000
  )
  const offset = date.getTimezoneOffset() / 60
  const hours = date.getHours()
  localDate.setHours(hours - offset)
  return localDate
}
