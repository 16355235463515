import Vue from 'vue'

Vue.component('section-loading-spinner', () =>
  import('@/components/global/SectionLoadingSpinner')
)

// Global components For Fltering Data

/*
 * it Contain [filter group, filter pills, MultiSelectList, Range Slider ]
 *
 *
 **/
Vue.component('data-filter', () =>
  import('@/components/global/filter/DataFilter')
)
Vue.component('filter-group', () =>
  import('@/components/global/filter/FilterGroup')
)
Vue.component('MultiSelectList', () =>
  import('@/components/global/filter/MultiSelectList')
)
Vue.component('RangeSlider', () =>
  import('@/components/global/filter/RangeSlider')
)

Vue.component('FilterPills', () =>
  import('@/components/global/filter/FilterPills')
)

/**
 * Mystery components
 */

Vue.component('TheMystery', () => import('@/components/mystery/TheMystery.vue'))
Vue.component('TheSuspense', () =>
  import('@/components/mystery/suspense/TheSuspense.vue')
)
Vue.component('SuspenseHeading', () =>
  import('@/components/mystery/suspense/SuspenseHeading.vue')
)
Vue.component('SuspenseImg', () =>
  import('@/components/mystery/suspense/SuspenseImg.vue')
)
Vue.component('SuspenseTab', () =>
  import('@/components/mystery/suspense/SuspenseTab.vue')
)
Vue.component('SuspenseText', () =>
  import('@/components/mystery/suspense/SuspenseText.vue')
)

// Others
Vue.component('InputErrorItem', () =>
  import('@/components/form/InputErrorItem')
)
Vue.component('SuspenseLoader', () =>
  import('@/components/loader/SuspenseLoader')
)

// Form
Vue.component('AppFormLabel', () => import('@/components/form/AppInputLabel'))
Vue.component('AppFormError', () => import('@/components/form/AppInputError'))
Vue.component('AppInput', () => import('@/components/form/AppInput'))
Vue.component('AppButton', () => import('@/components/form/AppButton'))
