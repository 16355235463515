import axios from 'axios'
// import xMan from "@/utils/xMan";
import { ApiConfig } from '@/config/ApiConfig'
import { VehicleConfig } from '@/config/VehicleConfig'

export default {
  namespaced: true,

  state: {
    // status (idle/busy/succeeded/failed)
    status: 'idle',
    // current vehicle data
    id: undefined,
    data: undefined,
  },
  getters: {
    getId: (state) => {
      return state.id || null
    },
    getData: (state) => {
      return state.data || null
    },
    getStatus: (state) => {
      return state.status
    },
    isLoading: (state) => {
      return state.status === 'busy' ?? false
    },
  },
  mutations: {
    STATUS_IDLE(state) {
      state.status = 'idle'
    },
    STATUS_BUSY(state) {
      state.status = 'busy'
    },
    STATUS_FAILED(state) {
      state.status = 'failed'
    },
    STATUS_SUCCEEDED(state) {
      state.status = 'succeeded'
    },
    REMEMBER_ID(state, payload) {
      state.id = payload
    },
    FORGET_ID(state) {
      state.id = undefined
    },
    REMEMBER_DATA(state, payload) {
      state.data = payload
    },
    FORGET_DATA(state) {
      state.data = undefined
    },
    // payload: { key: speed, value: 'M' }
    UPDATE_EXTRAS(state, payload) {
      state.data.scooter_extras[payload.key] = payload.value
    },
  },
  actions: {
    async fetchData({ commit }, payload) {
      commit('STATUS_BUSY')
      commit('FORGET_ID')
      commit('FORGET_DATA')
      commit('REMEMBER_ID', payload)
      await axios
        .get(VehicleConfig.api.single(payload), ApiConfig.options)
        .then((res) => {
          // console.log({vd: res.data});
          commit('REMEMBER_DATA', res.data)
          commit('STATUS_SUCCEEDED')
          return res
        })
        .catch((err) => {
          commit('STATUS_FAILED')
          // console.log({vde: err});
          throw new Error(err)
        })
        .finally(() => {
          commit('STATUS_IDLE')
        })
    },
    saveData({ commit }, payload) {
      commit('STATUS_BUSY')
      commit('FORGET_DATA')

      commit('REMEMBER_DATA', payload)
      console.log({ vdS: payload })
      commit('STATUS_SUCCEEDED')
      commit('STATUS_IDLE')
    },
    saveId({ commit }, payload) {
      commit('STATUS_BUSY')
      commit('FORGET_ID')

      commit('REMEMBER_ID', payload)

      commit('STATUS_SUCCEEDED')
      commit('STATUS_IDLE')
    },
    saveExtras({ commit }, payload) {
      commit('UPDATE_EXTRAS', payload)
    },
  },
}
