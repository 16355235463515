export default {
  api: {
    // get
    index: '/dashboard/riders/',
    // get
    single: (riderId = 'uuid') => {
      return `/dashboard/riders/${riderId}/`
    },
    // get
    paymentInfo: (riderId = 'uuid') => {
      return `/dashboard/riders/${riderId}/payment-info/`
    },
    // post
    balanceAdjustment: (riderId = 'uuid') => {
      return `/dashboard/riders/${riderId}/`
    },
    // get
    balanceAdjustmentLogs: (riderId = 'uuid') => {
      return `/dashboard/riders/${riderId}/balance-adjustment-records/`
    },
  },
  events: {
    refresh: `refresh-rider`,
    adjustBalancePop: `AdjustBalancePopup`,
  },
}
