import Vue from 'vue'
import mitt from 'mitt'
import { EventBus } from '@/utils/EventBus'
/**
 * This is specially for EdgeStack component event system.
 * It should not be used elsewhere.
 */
export const edgeStackEmitter = mitt()

/**
 * This class is for maintaining states of EdgeStack component easily
 * This is not a npm plugin, rather a local plugin for the app.
 */
export class EdgeStackManager {
  constructor() {
    this.debug = false
    this.emitter = edgeStackEmitter

    this.debug && console.log({ EdgeStackEvents: this.emitter.all })
  }

  // Emit events
  open(id) {
    const e = this.getEventName('open', id)

    this.emitter.emit(e)
    this.debug && console.log({ EdgeStackOpener: e })
  }

  close(id) {
    const e = this.getEventName('close', id)

    this.emitter.emit(e)
    this.debug && console.log({ EdgeStackCloser: e })
  }

  toggle(id) {
    const e = this.getEventName('toggle', id)

    this.emitter.emit(e)
    this.debug && console.log({ EdgeStackToggler: e })
  }

  shouldConfirm(id) {
    const e = this.getEventName('confirmFirst', id)

    this.emitter.emit(e, {
      confirmFirst: true,
    })
    this.debug &&
      console.log({
        EdgeStackConfirmFirst_Yes: e,
      })
  }

  shouldNotConfirm(id, senseTrack = '') {
    const e = this.getEventName('confirmFirst', id)
    EventBus.$emit('fresh-sense', senseTrack)
    this.emitter.emit(e, {
      confirmFirst: false,
    })
    this.debug &&
      console.log({
        EdgeStackConfirmFirst_No: e,
      })
  }

  // Get event name
  getEventName(type, id = null) {
    const prefixMap = {
      opening: 'es-opening:',
      open: 'es-open:',
      opened: 'es-opened:',

      closing: 'es-closing:',
      close: 'es-close:',
      closed: 'es-closed:',

      // emits opening, opened, closing, closed events
      // also emits toggled event with data { from: bool, to: bool }
      toggle: 'es-toggle:',
      toggled: 'es-toggled:',

      confirmFirst: 'es-confirm-first:',
    }

    if (prefixMap[type]) {
      const theId = id ? id : this.id
      return prefixMap[type].concat(theId)
    }

    this.debug && console.warn('es-trying-to-get-unknown-event-type')
    return 'es-trying-to-get-unknown-event-type'
  }
}

export const edgeStack = new EdgeStackManager()
export const useEdgeStack = () => edgeStack

Vue.prototype.$edgeStack = edgeStack
