/*
example payload format
{
    step: number [required]
    data: object [optional]
}
*/
import ReconnectingWebsocket from 'reconnectingwebsocket'
import { SocketConfig } from '@/config/SocketConfig'
import { genericEquals } from '@/utils/Helpers'

export default {
  namespaced: true,

  state: {
    // status (idle/busy/succeeded/failed)
    status: 'idle',
    // current step
    step: undefined,
    // steps data
    vehicles: null,
    selected_vehicles: null,
    service_area: null,
    parking_area: null,
    restricted_area: null,
    slowzone_area: null,
    summery: null,

    //status
    filter_fleet: '',
    filter_battery: '',
    filter_gps: '',
    search_text: '',
    //
    // current step that is being edited (undefined/number)

    serviceAreaId: undefined,

    editingStep: undefined,
    editingScooter: undefined,
    sockListener: null,
    isRealtime: true,
  },
  getters: {
    currentServiceAreaId: (state) => {
      return state.serviceAreaId || ''
    },
    realtimeStatus: (state) => {
      return state.isRealtime
    },
  },
  mutations: {
    STATUS_IDLE(state) {
      state.status = 'idle'
    },
    STATUS_BUSY(state) {
      state.status = 'busy'
    },
    STATUS_FAILED(state) {
      state.status = 'failed'
    },
    STATUS_SUCCEEDED(state) {
      state.status = 'succeeded'
    },
    REMEMBER_VEHICLES_DATA(state, payload) {
      state.vehicles = payload.data
    },
    REMEMBER_SELECTED_VEHICLES_DATA(state, payload) {
      state.vehicles = payload.data
      state.selected_vehicles = payload.data
    },
    //
    REMEMBER_SERVICES_DATA(state, payload) {
      state.service_area = payload.data
    },
    REMEMBER_PARKING_DATA(state, payload) {
      state.parking_area = payload.data
    },
    REMEMBER_RESTRICTED_DATA(state, payload) {
      state.restricted_area = payload.data
    },
    REMEMBER_SUMMERY(state, payload) {
      state.summery = payload.data
    },
    REMEMBER_SLOWZONE_DATA(state, payload) {
      state.slowzone_area = payload.data
    },
    //
    REMEMBER_FILTER_FLEET_DATA(state, payload) {
      state.filter_fleet = payload.data
    },

    REMEMBER_FILTER_BATTERY_DATA(state, payload) {
      state.filter_battery = payload.data
    },

    REMEMBER_FILTER_GPS_DATA(state, payload) {
      state.filter_gps = payload.data
    },

    REMEMBER_SEARCH_TEXT(state, payload) {
      state.search_text = payload.data
    },
    //
    FORGET_VEHICLES_DATA(state) {
      state.vehicles = null
    },
    FORGET_SERVICES_DATA(state) {
      state.service_area = null
    },
    FORGET_ALL_DATA(state) {
      state.vehicles = null
      state.selected_vehicles = null
      state.service_area = null
      state.parking_area = null
      state.restricted_area = null
      state.slowzone_area = null
      state.summery = null
      state.search_text = null
      state.filter_battery = null
      state.filter_fleet = null
      state.filter_gps = null
    },
    REMEMBER_CURRENT_SERVICE_AREA_ID(state, payload) {
      state.serviceAreaId = payload.data
    },
    FORGET_CURRENT_SERVICE_AREA_ID(state) {
      state.serviceAreaId = undefined
    },
    TOGGLE_REALTIME_STATE(state) {
      state.isRealtime = !state.isRealtime
    },
    REMOVE_BIKE_UPDATES_LISTENER(state) {
      if (state.sockListener) {
        state.sockListener.close(
          4003,
          'oto system closed the socket to refresh stream'
        )
        state.sockListener = null
      }
    },
    LISTEN_BIKE_UPDATES(state) {
      if (!state.isRealtime) return
      let token = localStorage.getItem('token') || null
      // console.log(`Fetched Token : ${token}`)
      if (!token) return
      let sockUrl =
        SocketConfig.baseUrl +
        SocketConfig.channels.bikeUpdates +
        `?token=${token}`

      state.sockListener = state.sockListener
        ? state.sockListener
        : new ReconnectingWebsocket(sockUrl, null, SocketConfig.configs())

      state.sockListener.onopen = function() {
        console.log('Opened Connection...')
        // state.sockListener.close(4003, 'forced disconnect after opening')
      }

      state.sockListener.onclose = function() {
        console.log('Closed Connection...')
      }

      state.sockListener.onmessage = function(message) {
        console.log('Incoming --> ', message)
        let payload = JSON.parse(message.data)
        if (!payload.n_type === 'noti.bike_updates') return

        var data = payload.data
        console.log('Recieved Payload...', data)

        let bikeId = data['id']
        if (!bikeId) return
        delete data['id']

        const bikeIndex = state.selected_vehicles.findIndex(
          (item) => item.id === bikeId
        )
        console.log('Index on local state --> ', bikeIndex)
        var bikeData = state.selected_vehicles[bikeIndex]
        if (!bikeData) {
          console.warn('BIKE NOT MATCHED BY ID')
          return
        }
        console.log(bikeData)
        let skipSave = true
        for (const key in data) {
          console.log(
            `Incoming data for bike ${bikeId} : ${key} --> ${data[key]}`
          )
          if (genericEquals(bikeData[key], data[key])) {
            console.log(`Skipping updates : Has same data --> ${bikeData[key]}`)
          } else {
            const prevData = bikeData[key]
            bikeData[key] = data[key]
            console.log(
              `Updated data for bike ${bikeId} | ${key} : ${prevData} --> ${data[key]}`
            )
            skipSave = false
          }
        }

        if (!skipSave) state.selected_vehicles[bikeIndex] = bikeData
        console.log(
          `Save data for bike ${bikeId} | ${skipSave ? 'Skipped' : 'Success'}`
        )
      }
    },
  },
  actions: {
    saveServiceAreaId({ commit }, payload) {
      commit('STATUS_BUSY')

      commit('REMEMBER_CURRENT_SERVICE_AREA_ID', payload)

      commit('STATUS_SUCCEEDED')
      commit('STATUS_IDLE')
    },
    forgetServiceAreaId({ commit }) {
      commit('STATUS_BUSY')

      commit('FORGET_CURRENT_SERVICE_AREA_ID')

      commit('STATUS_SUCCEEDED')
      commit('STATUS_IDLE')
    },
    saveVehicleData({ commit }, payload) {
      commit('STATUS_BUSY')
      commit('REMEMBER_VEHICLES_DATA', payload)

      commit('STATUS_SUCCEEDED')
      commit('STATUS_IDLE')
    },
    saveSelectedVehicleData({ commit }, payload) {
      commit('STATUS_BUSY')
      commit('REMEMBER_SELECTED_VEHICLES_DATA', payload)
      commit('STATUS_SUCCEEDED')
      commit('STATUS_IDLE')
      commit('LISTEN_BIKE_UPDATES')
    },
    saveServicesData({ commit }, payload) {
      commit('REMEMBER_SERVICES_DATA', payload)
    },
    saveParkingData({ commit }, payload) {
      commit('REMEMBER_PARKING_DATA', payload)
    },
    saveRestrictedData({ commit }, payload) {
      commit('REMEMBER_RESTRICTED_DATA', payload)
    },
    saveSummery({ commit }, payload) {
      commit('REMEMBER_SUMMERY', payload)
    },
    saveSlowZoneData({ commit }, payload) {
      commit('REMEMBER_SLOWZONE_DATA', payload)
    },
    saveFilterFleet({ commit }, payload) {
      commit('REMEMBER_FILTER_FLEET_DATA', payload)
    },
    saveFilterBattery({ commit }, payload) {
      commit('REMEMBER_FILTER_BATTERY_DATA', payload)
    },
    saveFilterGps({ commit }, payload) {
      commit('REMEMBER_FILTER_GPS_DATA', payload)
    },
    saveSearchText({ commit }, payload) {
      commit('REMEMBER_SEARCH_TEXT', payload)
    },

    forgetAllData({ commit }) {
      commit('STATUS_BUSY')

      commit('FORGET_ALL_DATA')

      commit('STATUS_SUCCEEDED')
      commit('STATUS_IDLE')
    },
    openSockListener({ commit }) {
      commit('LISTEN_BIKE_UPDATES')
    },
    removeSockListener({ commit }) {
      commit('REMOVE_BIKE_UPDATES_LISTENER')
    },
    toggleRealtimeMode({ commit, state }) {
      commit('TOGGLE_REALTIME_STATE')
      if (state.isRealtime) {
        commit('LISTEN_BIKE_UPDATES')
      } else {
        commit('REMOVE_BIKE_UPDATES_LISTENER')
      }
    },
  },
}
