import Vue from 'vue'
import Vuex from 'vuex'
// import createPersistedState from 'vuex-persistedstate'
import LogRocket from 'logrocket'
import createPlugin from 'logrocket-vuex'

// import the auto exporter
import modules from './modules'

Vue.use(Vuex)

export const store = new Vuex.Store({
  modules,

  state: {},

  getters: {},

  mutations: {},

  actions: {},

  plugins: [createPlugin(LogRocket)], // createPersistedState()
})

export default store
export const useStore = () => store
