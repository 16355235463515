import Vue from 'vue'
import dayjs from 'dayjs'

export const getUTCAwareTime = (
  time,
  {
    format = 'DD MMM, YY hh:mm:ss a',
    fallbackText = '--',
    relative = false,
    forceUtc = true,
  } = {}
) => {
  // add Z if not present
  if (forceUtc && time && !time.includes('Z')) {
    time += 'Z'
  }

  const d = dayjs(time)
  if (!d.isValid()) return fallbackText

  // always return a time in UTC

  // removal of timezone offset is not needed as it is already UTC
  /* let t
  if (d.isUTC()) {
    t = d
  } else {
    // local timezone offset in hours
    const clientTimezoneOffsetHours = (new Date().getTimezoneOffset() / 60) * -1
    t = dayjs(d.utc()).add(clientTimezoneOffsetHours, 'hours')
  } */

  if (relative) return d.local().fromNow()

  if (format) return d.format(format)

  return d.toISOString()
}

Vue.prototype.$UTCAwareTime = getUTCAwareTime
