import Vue from 'vue'

// Vue media queries
import { MediaQueries } from 'vue-media-queries'

// Tailwind Bands
const TailwindBands = {
  // Named Media Queries
  smUp: '(min-width: 640px)',
  smDown: '(max-width: 767.98px)',
  smOnly: '(min-width: 640px) and (max-width: 767.98px)',

  mdUp: '(min-width: 768px)',
  mdDown: '(max-width: 1023.98px)',
  mdOnly: '(min-width: 768px) and (max-width: 1023.98px)',

  lgUp: '(min-width: 1024px)',
  lgDown: '(max-width: 1279.98px)',
  lgOnly: '(min-width: 1024px) and (max-width: 1279.98px)',

  xlUp: '(min-width: 1280px)',
  xlOnly: '(min-width: 1280px)',
}

// Media Query Helpers mixin. Use globally or per component
export const mqMixin = {
  computed: {
    isSmUp() {
      return this.$resize && this.$mq.expr(this.$mq.bands.smUp)
    },
    isSmDown() {
      return this.$resize && this.$mq.expr(this.$mq.bands.smDown)
    },
    isSmOnly() {
      return this.$resize && this.$mq.expr(this.$mq.bands.smOnly)
    },

    isMdUp() {
      return this.$resize && this.$mq.expr(this.$mq.bands.mdUp)
    },
    isMdDown() {
      return this.$resize && this.$mq.expr(this.$mq.bands.mdDown)
    },
    isMdOnly() {
      return this.$resize && this.$mq.expr(this.$mq.bands.mdOnly)
    },

    isLgUp() {
      return this.$resize && this.$mq.expr(this.$mq.bands.lgUp)
    },
    isLgDown() {
      return this.$resize && this.$mq.expr(this.$mq.bands.lgDown)
    },
    isLgOnly() {
      return this.$resize && this.$mq.expr(this.$mq.bands.lgOnly)
    },

    isXlUp() {
      return this.$resize && this.$mq.expr(this.$mq.bands.xlUp)
    },
    isXlOnly() {
      return this.$resize && this.$mq.expr(this.$mq.bands.xlOnly)
    },
  },
}

// IMPORTANT: the exported `mqInstance` needs to be passed to the Vue Instance
// for i.e. new Vue({mediaQueries: mqInstance, render: (h) => h(App),}).$mount('#app')

export const mqInstance = new MediaQueries({
  bands: TailwindBands,
})

Vue.use(mqInstance)

/*
  mqMixin: Tailwind
        <div><strong>Current Screen Size(s):</strong></div>
        <div v-show="isSmUp">is <code>sm</code> And Up</div>
        <div v-show="isSmDown">is <code>sm</code> And Down</div>
        <div v-show="isSmOnly">is <code>sm</code> And Only</div>

        <div v-show="isMdUp">is <code>md</code> And Up</div>
        <div v-show="isMdDown">is <code>md</code> And Down</div>
        <div v-show="isMdOnly">is <code>md</code> And Only</div>

        <div v-show="isLgUp">is <code>lg</code> And Up</div>
        <div v-show="isLgDown">is <code>lg</code> And Down</div>
        <div v-show="isLgOnly">is <code>lg</code> And Only</div>

        <div v-show="isXlUp">is <code>xl</code> And Up</div>
        <div v-show="isXlOnly">is <code>xl</code> And Only</div>
*/
