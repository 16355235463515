import store from '../store'

export const ifNotAuthenticated = (to, from, next) => {
  if (store.getters['auth/status'] === false) {
    //console.log('unauthenticated');
    next()
    return
  }
  next('/')
}
