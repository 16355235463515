export default {
  api: {
    // get
    index: '/dashboard/reports/',
    // get
    single: (reportId = 'uuid') => {
      return `/dashboard/reports/${reportId}/`
    },
    // post
    create: '/dashboard/reports/',
    // patch
    update: (reportId = 'uuid') => {
      return `/dashboard/reports/${reportId}/`
    },
  },
  events: {
    refresh: `refresh-report`,
  },
}
