import store from '../store'

export const ifAuthenticated = (to, from, next) => {
  if (store.getters['auth/status'] === true) {
    //console.log(store.getters["auth/status"]);
    //console.log('authenticated');
    next()
    return
  }
  next('/login')
}
