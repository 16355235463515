import states from './module.states'
import getters from './module.getters'
import mutations from './module.mutations'
import actions from './module.actions'

export default {
  namespaced: true,
  state: states,
  getters,
  mutations,
  actions,
}
